import { Link } from "@react-navigation/native";
import {
    CheckBox,
    DataTable,
    IconButton,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import React, { useCallback } from "react";

import type { GQTagGroupsQuery } from "graphql-sdk";
import { StyleProp, ViewStyle } from "react-native";
export function Row({
    item,
    selectedTagGroup,
    onSelect,
    onMultiSelectChange,
    onDelete,
    style,
}: {
    item: GQTagGroupsQuery["tagGroups"]["data"][0];
    selectedTagGroup: boolean;
    onSelect: () => void;
    onMultiSelectChange: (selected: boolean, itemIds: string[]) => void;
    onDelete: () => void;
    style?: StyleProp<ViewStyle>;
}) {
    const styles = useThemedStyle(styleFunc);

    const handleMultiSelectChange = useCallback(
        value => onMultiSelectChange(value, [item.id]),
        [item, onMultiSelectChange]
    );

    return (
        <DataTable.Row style={style}>
            <DataTable.Cell style={styles.iconColumn}>
                <CheckBox
                    value={selectedTagGroup}
                    onValueChange={handleMultiSelectChange}
                    style={styles.checkbox}
                />
            </DataTable.Cell>
            <DataTable.Cell>
                <Link
                    to={{
                        screen: "TAG_GROUP_EDIT",
                        params: { id: item.id },
                    }}
                    style={styles.textLink}
                >
                    {item.name}
                </Link>
            </DataTable.Cell>
            <DataTable.Cell numeric style={styles.sortIconColumn}>
                <IconButton
                    name="delete"
                    onPress={onDelete}
                    color={styles.sortIconColumn.color}
                />
                <IconButton
                    name="edit"
                    onPress={onSelect}
                    color={styles.sortIconColumn.color}
                />
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = theme => ({
    checkbox: {
        marginVertical: 8,
    },
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 40,
        color: theme.colors.secondary,
    },
    sortIconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 100,
        color: theme.colors.secondary,
    },
    textLink: {
        ...theme.styles.link,
    },
});

import {
    Alert,
    Button,
    ColorIndicator,
    DataTable,
    Icon,
    IconButton,
    Loading,
    Surface,
    useAuth,
    useConfirm,
    usePagination,
    useTheme,
} from "@venuepos/react-common";
import {
    useIntegrationDeleteMutation,
    useIntegrationsQuery,
} from "graphql-sdk";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { StyleSheet, View } from "react-native";

import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";
import { IntegrationType, integrationTypes } from "lib";
import { useHandleMutationError } from "../../hooks";

type ScreenProps = RootStackScreenProps<"INTEGRATIONS">;

export function IntegrationsScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.merchant_settings");

    const [t] = useTranslation();
    const theme = useTheme();
    const confirm = useConfirm();
    const [deleteIntegration] = useIntegrationDeleteMutation();
    const { handleMutationError } = useHandleMutationError();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({ initialSortBy: "name", initialSortDirection: "ASC" });

    const { data, loading, error, refetch } = useIntegrationsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
        },
        fetchPolicy: "no-cache",
    });

    // This is all the created types. There should only be one of each.
    // (Apparently that's a limit we set for our users at some point...)
    const createdTypes: IntegrationType[] = data?.integrations.data
        ? data?.integrations.data.map(itr => itr.type as IntegrationType)
        : [];

    // The set of created types - this should at most limit to the number of available types.
    const uniqueCreatedTypes = Array.from(new Set(createdTypes));

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const handleEdit = useCallback(
        (id: string) => {
            navigate("INTEGRATION_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleDelete = useCallback(
        async (id: string) => {
            if (
                !(await confirm(
                    t("backoffice.integration.delete", "Delete Integration?"),
                    t(
                        "backoffice.integration.delete_explain",
                        "If you choose to delete this integration, you will not be able to transfer or fetch data with this integration.\n\nAre you sure, you want to delete?"
                    )
                ))
            ) {
                return;
            }

            await handleMutationError(
                async () => await deleteIntegration({ variables: { id } }),
                t(
                    "backoffice.integration.deleted.success",
                    "Integration deleted"
                ),
                async () => {
                    await refetch();
                }
            );
        },
        [confirm, deleteIntegration, handleMutationError, refetch, t]
    );

    const renderIntegrationRow = useCallback(
        (item, index) => (
            <DataTable.Row key={index.toString()}>
                <DataTable.Cell
                    onPress={() => handleEdit(item.id)}
                    style={styles.iconColumn}
                >
                    <ColorIndicator
                        status={item.active ? "success" : "warning"}
                    />
                </DataTable.Cell>
                <DataTable.Cell onPress={() => handleEdit(item.id)}>
                    {item.name}
                </DataTable.Cell>
                <DataTable.Cell onPress={() => handleEdit(item.id)}>
                    {item.type}
                </DataTable.Cell>
                <DataTable.Cell numeric>
                    <IconButton
                        color={theme.colors.secondary}
                        name="edit"
                        onPress={() => handleEdit(item.id)}
                    />
                    {auth.may("merchant.merchant_settings") && (
                        <IconButton
                            color={theme.colors.secondary}
                            name="delete"
                            onPress={() => handleDelete(item.id)}
                            testID={"delete:" + item.name}
                        />
                    )}
                </DataTable.Cell>
            </DataTable.Row>
        ),
        [auth, handleDelete, handleEdit, theme.colors.secondary]
    );

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error.message,
                    }
                )}
            </Alert>
        );
    }
    return (
        <AdminContainer>
            <View style={styles.container}>
                <Button
                    style={styles.button}
                    onPress={() => {
                        navigate("INTEGRATION_CREATE");
                    }}
                    disabled={
                        uniqueCreatedTypes.length === integrationTypes.length
                    }
                >
                    {t(
                        "backoffice.integration_list.create_integration",
                        "Create integration"
                    )}
                </Button>
            </View>
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "active" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("active")}
                            style={styles.iconColumn}
                        >
                            {t("common.active", "Active")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "name" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "type" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("type")}
                        >
                            {t("common.type", "Type")}
                        </DataTable.Title>
                        <DataTable.Title style={styles.sortIcon}>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {(loading && <Loading />) ||
                        data!.integrations.data.map(renderIntegrationRow)}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.integrations.pagination.pages}
                        itemCount={data?.integrations.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styles = StyleSheet.create({
    container: {
        justifyContent: "space-between",
        marginBottom: 20,
        flexWrap: "wrap",
    },
    button: { width: 200, alignSelf: "flex-end" },
    sortIcon: {
        justifyContent: "flex-end",
    },
    iconColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 60 },
});

import {
    useFocusEffect,
    useRoute,
    RouteProp,
    useNavigation,
} from "@react-navigation/native";
import {
    Alert,
    Button,
    CheckBox,
    ConfirmModal,
    DataTable,
    Icon,
    Loading,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    useModal,
    usePagination,
    useSearch,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQTagGroupsQuery,
    useTagGroupDeleteMutation,
    useTagGroupsQuery,
} from "graphql-sdk";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { useMultiSelect } from "../../../hooks/use-multi-select";
import { RootStackParamList } from "../../../navigation";
import { Row } from "./row";
import { SearchDefinition } from "lib";
import { useSearchDefinition } from "../../../hooks";

export function TagGroupsTab() {
    const auth = useAuth();
    auth.enforce("merchant.tag");

    const route = useRoute<RouteProp<RootStackParamList, "TAG_GROUPS">>();
    const { navigate } = useNavigation();

    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const [t] = useTranslation();
    const { createSearchDefinition } = useSearchDefinition();
    const { render } = useModal();
    const { handleMutationError } = useHandleMutationError();
    const [deleteTagGroup] = useTagGroupDeleteMutation();

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({ initialSortBy: "name" });

    const defaultTagGroupSearch: SearchDefinition = {
        name: createSearchDefinition("name"),
        createdAt: createSearchDefinition("createdAt"),
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultTagGroupSearch, {
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    const {
        selectedItems: selectedTagGroups,
        setSelectedItems: setSelectedTagGroups,
        handleMultiSelect,
        allItemsSelected: allRowsSelected,
        setAllItemsSelected: setAllRowsSelected,
    } = useMultiSelect<GQTagGroupsQuery["tagGroups"]["data"][0]["id"]>();

    // GraphQL
    const { data, loading, error, refetch } = useTagGroupsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    useFocusEffect(
        useCallback(() => {
            refetch();
        }, [refetch])
    );

    // Actions
    const handleEdit = useCallback(
        (id: string) => {
            navigate("TAG_GROUP_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleDelete = useCallback(
        async (id: string) => {
            if (
                !(await render(onClose => (
                    <ConfirmModal
                        headerText={t(
                            "backoffice.tags.delete_tag_group",
                            "Delete Tag Group?"
                        )}
                        bodyText={t(
                            "backoffice.layout.delete_tag_group_explain",
                            "Are you sure you want to delete this group?"
                        )}
                        onClose={onClose}
                    />
                )))
            ) {
                return;
            }

            await handleMutationError(
                async () => {
                    await deleteTagGroup({ variables: { id } });
                },
                t("common.deleted", "Deleted"),
                () => {
                    navigate("TAGS", { refetch: true });
                }
            );
        },
        [deleteTagGroup, handleMutationError, navigate, render, t]
    );

    const deleteSelectedTagGroups = useCallback(async () => {
        selectedTagGroups.forEach(async tagGroupId => {
            await handleDelete(tagGroupId).then(() => {
                setSelectedTagGroups(
                    selectedTagGroups.filter(p => p !== tagGroupId)
                );
                return;
            });
        });
    }, [handleDelete, selectedTagGroups, setSelectedTagGroups]);

    const renderTagGroupRow = useCallback(
        (item: GQTagGroupsQuery["tagGroups"]["data"][0], index: number) => (
            <Row
                item={item}
                key={index.toString()}
                selectedTagGroup={selectedTagGroups.includes(item.id)}
                onSelect={() => handleEdit(item.id)}
                onMultiSelectChange={handleMultiSelect}
                onDelete={() => handleDelete(item.id)}
                style={index % 2 === 0 ? styles.oddRow : undefined}
            />
        ),
        [
            handleDelete,
            handleEdit,
            handleMultiSelect,
            selectedTagGroups,
            styles.oddRow,
        ]
    );

    return (
        <>
            <View style={[theme.styles.row, styles.container]}>
                <Button
                    variant="invert"
                    disabled={!selectedTagGroups.length}
                    onPress={deleteSelectedTagGroups}
                >
                    {t("common.delete", "Delete")}
                </Button>
                <Spacer space={1} />
                <Button
                    type="secondary"
                    onPress={() => {
                        navigate("TAG_GROUP_CREATE");
                    }}
                >
                    {t("common.create", "Create")}
                </Button>
                <Spacer space={1} />
                {searchIndicator}
            </View>
            <Spacer space={2} />
            <Surface>
                {searchComponent}
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title style={styles.iconColumn}>
                            <CheckBox
                                value={allRowsSelected}
                                onValueChange={value => {
                                    const allItemIds = data!.tagGroups.data.map(
                                        p => p!.id
                                    );
                                    handleMultiSelect(value, allItemIds);
                                    setAllRowsSelected(value);
                                }}
                            />
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "name" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title numeric style={styles.iconColumn}>
                            <Icon name="sort" color={styles.iconColumn.color} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {error ? (
                        <Alert type="error">
                            {t(
                                "backoffice.error.from_server",
                                "There was an error: {{errorText}}",
                                {
                                    errorText: error.message,
                                }
                            )}
                        </Alert>
                    ) : loading ? (
                        <>
                            <Spacer />
                            <Loading />
                            <Spacer />
                        </>
                    ) : (
                        data?.tagGroups.data.map(renderTagGroupRow)
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.tagGroups.pagination.pages}
                        itemCount={data?.tagGroups.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "flex-end",
        flexWrap: "wrap",
    },
    iconColumn: {
        flexBasis: 40,
        flexGrow: 0,
        flexShrink: 0,
        color: theme.colors.secondary,
    },

    oddRow: {
        ...theme.styles.oddRow,
    },
});

import React, { useMemo } from "react";

import { Providers, AdminNavigator } from "./screens";

import { setupReact } from "locales";
import { withProfiler } from "@venuepos/react-common";
import { GestureHandlerRootView } from "react-native-gesture-handler";

setupReact();

export function App() {
    const styles = useMemo(
        () => ({
            container: { flex: 1 },
        }),
        []
    );

    return (
        <GestureHandlerRootView style={styles.container}>
            <Providers>
                <AdminNavigator />
            </Providers>
        </GestureHandlerRootView>
    );
}

export default withProfiler(App);

import React from "react-native";
import { Icon, useTheme } from "@venuepos/react-common";
import { DropdownIndicatorProps, IndicatorSeparatorProps } from "react-select";

// Component for removing the | just left of the dropdown indicator
const IndicatorSeparator = ({}: IndicatorSeparatorProps<any, true>) => null;

// Make the dropdown indicator look like the existing ones
const CustomDropdownIndicator = ({}: DropdownIndicatorProps<any, true>) => {
    const theme = useTheme();
    return <Icon name="dropdown" size="normal" color={theme.colors.grey800} />;
};

export const selectComponents = {
    IndicatorSeparator,
    DropdownIndicator: CustomDropdownIndicator,
};

/* istanbul ignore file */
import type { IMerchantChartOfAccountInput } from "../..";

export type IntegrationType = "E-CONOMIC" | "PARTNER-API" | "VENUE-ACCESS";

export const integrationTypes: IntegrationType[] = [
    "E-CONOMIC",
    "PARTNER-API",
    "VENUE-ACCESS",
];

export type IIntegration<Type extends IntegrationType = IntegrationType> = {
    id: string;
    merchantId: string;
    type?: Type;
    active: boolean;
    name: string;
    apiKey: string;
    createdAt: Date;
    deletedAt: Date | null;
    updatedAt: Date | null;
    merchantChartOfAccounts: IMerchantChartOfAccountInput[];
};

export type IIntegrationInput = Omit<
    IIntegration,
    "id" | "createdAt" | "deletedAt" | "updatedAt"
>;

export type IIntegrationApiKey = Pick<
    IIntegration,
    "apiKey" | "merchantId" | "type"
>;

export interface IntegrationSettings {
    id: string;
    type: IntegrationType;
}

export interface EconomicSettings extends IntegrationSettings {
    journalId: number;
    type: "E-CONOMIC";
}

export interface PartnerApiSettings extends IntegrationSettings {
    type: "PARTNER-API";
}

export interface VenueAccessSettings extends IntegrationSettings {
    type: "VENUE-ACCESS";
}

// Input types

export type IntegrationSettingsInput = Omit<IntegrationSettings, "id" | "type">;

export type EconomicSettingsInput = Omit<EconomicSettings, "id" | "type">;

import React from "react";
import { InputControl, TextInput } from "@venuepos/react-common";
import { View } from "react-native";
import type { TFunction } from "i18next";
import { IUserPassword, ValidationErrors } from "lib";

export function PasswordInput({
    errors,
    setValue,
    t,
    values,
}: {
    errors: ValidationErrors<IUserPassword>;
    setValue: <Field extends keyof IUserPassword>(
        field: Field,
        value: string
    ) => void;
    t: TFunction;
    values: any;
}) {
    return (
        <View>
            <InputControl
                error={errors.password}
                description={
                    t(
                        "backoffice.password_form.password_rules",
                        "Must be at least 8 characters"
                    ) as string
                }
            >
                <TextInput
                    label={t("backoffice.user_form.password", "Password")}
                    placeholder={t(
                        "backoffice.user_form.enter_password",
                        "Enter password"
                    )}
                    secureTextEntry={true}
                    defaultValue={values.password}
                    onChangeText={text => setValue("password", text)}
                    required={true}
                />
            </InputControl>
            <InputControl error={errors.confirmPassword}>
                <TextInput
                    label={t(
                        "backoffice.user_form.confirm_password",
                        "Confirm password"
                    )}
                    placeholder={t(
                        "backoffice.user_form.enter_confirm_password",
                        "Repeat the chosen password"
                    )}
                    secureTextEntry={true}
                    onChangeText={text => setValue("confirmPassword", text)}
                    required={true}
                />
            </InputControl>
        </View>
    );
}

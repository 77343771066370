import React from "react";
import type { IMenuItem } from "@venuepos/react-common";
import { ScrollView } from "react-native";
import { StyleFunction, useThemedStyle } from "@venuepos/react-common";

export function AdminContainer({
    children,
    testID,
}: {
    children: React.ReactNode;
    extraMenuItems?: IMenuItem[];
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);

    return (
        <ScrollView style={styles.adminContainer} testID={testID}>
            {children}
        </ScrollView>
    );
}

const styleFunc: StyleFunction = theme => ({
    adminContainer: {
        flex: 1,
        paddingHorizontal: theme.spacingScale * 2,
        paddingVertical: theme.spacingScale * 2,
    },
});

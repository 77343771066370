export type GridDimensions = { columns: number; rows: number };
export type LayoutGridDefinition = GridDimensions & {
    label: string;
};

///////////////////////////
// Layout Grid Definitions
// ========================
// These are the available sizes, the user can select when creating a layout
export const layoutPortrait: LayoutGridDefinition = {
    label: "Portrait (4x6)",
    columns: 4,
    rows: 6,
};

export const layoutSize: LayoutGridDefinition = {
    label: "Landscape (6x5)",
    columns: 6,
    rows: 5,
};

export const tableLayout: LayoutGridDefinition = {
    label: "Table (40x20)",
    columns: 40,
    rows: 20,
};

export const layoutRestaurantSmall: LayoutGridDefinition = {
    label: "Restaurant (small)",
    columns: 16,
    rows: 8,
};

export const layoutRestaurantMedium: LayoutGridDefinition = {
    label: "Restaurant (medium)",
    columns: 20,
    rows: 10,
};

export const layoutRestaurantLarge: LayoutGridDefinition = {
    label: "Restaurant (large)",
    columns: 40,
    rows: 20,
};

export const layoutDesktop: LayoutGridDefinition = {
    label: "Desktop (6x5)",
    columns: 6,
    rows: 5,
};

export const layoutSunmiD3: LayoutGridDefinition = {
    label: "Sunmi D3 Mini (5x5)",
    columns: 5,
    rows: 5,
};

export const layoutMobile: LayoutGridDefinition = {
    label: "Mobile (2x5)",
    columns: 2,
    rows: 5,
};

export const defaultProductLayout = layoutDesktop;

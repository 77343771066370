import { Link } from "@react-navigation/native";
import { useThemedStyle, StyleFunction, Text } from "@venuepos/react-common";
import { GQCustomerQuery } from "graphql-sdk";
import { useTranslation } from "locales";
import React from "react";
import { Linking, View } from "react-native";

export function CustomerInfo({
    data: customer,
    balance,
    onAssignTags,
}: {
    data: GQCustomerQuery["customer"];
    balance: string;
    onAssignTags: () => {};
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const handleOpenExternalUrl = (externalUrl?: string) => {
        if (!externalUrl) {
            return;
        }

        Linking.openURL(externalUrl).catch(err =>
            console.error("An error occured", err)
        );
    };

    return (
        <>
            <View style={[styles.row, styles.firstRow]}>
                <View style={styles.labelHolder}>
                    <Text style={styles.labelText}>{`${t(
                        "backoffice.customer.balance",
                        "Balance"
                    )}:`}</Text>
                </View>
                <View style={styles.valueHolder}>
                    <Text>{balance}</Text>
                </View>
            </View>

            <View style={styles.row}>
                <View style={styles.labelHolder}>
                    <Text style={styles.labelText}>{`${t(
                        "common.name",
                        "Name"
                    )}:`}</Text>
                </View>
                <View style={styles.valueHolder}>
                    <Text>{customer.name}</Text>
                </View>
            </View>

            {!!customer.contactName && (
                <View style={styles.row}>
                    <View style={styles.labelHolder}>
                        <Text style={styles.labelText}>{`${t(
                            "backoffice.customer.contact_name",
                            "Att."
                        )}:`}</Text>
                    </View>
                    <View style={styles.valueHolder}>
                        <Text>{customer.contactName}</Text>
                    </View>
                </View>
            )}

            <View style={styles.row}>
                <View style={styles.labelHolder}>
                    <Text style={styles.labelText}>{`${t(
                        "common.address",
                        "Address"
                    )}:`}</Text>
                </View>
                <View style={styles.valueHolder}>
                    <Text>{`${customer.address}, ${customer.zip} ${customer.city}`}</Text>
                </View>
            </View>

            <View style={styles.row}>
                <View style={styles.labelHolder}>
                    <Text style={styles.labelText}>{`${t(
                        "backoffice.customer.contact",
                        "Contact"
                    )}:`}</Text>
                </View>
                <View style={[styles.valueHolder, styles.tags]}>
                    <Text
                        role="link"
                        dataDetectorType="email"
                        onPress={() =>
                            handleOpenExternalUrl(
                                customer.email
                                    ? "mailto:" + customer.email
                                    : undefined
                            )
                        }
                        style={styles.textLink}
                    >
                        {customer.email}
                    </Text>
                    {customer.phoneNumber ? (
                        <>
                            <Text>, </Text>
                            <Text
                                dataDetectorType="phoneNumber"
                                onPress={() =>
                                    handleOpenExternalUrl(
                                        customer.phoneNumber
                                            ? "tel:" + customer.phoneNumber
                                            : undefined
                                    )
                                }
                                style={styles.textLink}
                            >{`${t("common.phone", "Phone")}: ${
                                customer.phoneNumber
                            }`}</Text>
                        </>
                    ) : null}
                </View>
            </View>

            {!!customer.cvrNumber && (
                <View style={styles.row}>
                    <View style={styles.labelHolder}>
                        <Text style={styles.labelText}>{`${t(
                            "backoffice.customer.cvr_number",
                            "CVR no."
                        )}:`}</Text>
                    </View>
                    <View style={styles.valueHolder}>
                        <Text>{customer.cvrNumber}</Text>
                    </View>
                </View>
            )}

            {customer.group?.name && (
                <View style={styles.row}>
                    <View style={styles.labelHolder}>
                        <Text style={styles.labelText}>{`${t(
                            "common.group",
                            "Group"
                        )}:`}</Text>
                    </View>
                    <View style={styles.valueHolder}>
                        <Link
                            to={{
                                screen: "CUSTOMER_GROUP_EDIT",
                                params: { id: customer.group.id },
                            }}
                            style={styles.textLink}
                        >
                            {customer.group.name}
                        </Link>
                    </View>
                </View>
            )}

            {!customer.comment ? null : (
                <View style={styles.row}>
                    <View style={styles.labelHolder}>
                        <Text style={styles.labelText}>{`${t(
                            "backoffice.customer.comments",
                            "Comments"
                        )}:`}</Text>
                    </View>
                    <View style={styles.valueHolder}>
                        <Text>{customer.comment}</Text>
                    </View>
                </View>
            )}

            <View style={styles.row}>
                <View style={styles.labelHolder}>
                    <Text style={styles.labelText}>{`${t(
                        "backoffice.customer.tags",
                        "Tags"
                    )}:`}</Text>
                </View>
                <View style={styles.valueHolder}>
                    {customer.tags.data.length > 0 ? (
                        customer.tags.data.slice(0, 10).map((tag, index) => (
                            <React.Fragment key={tag.id}>
                                <Link
                                    to={{
                                        screen: "TAG_EDIT",
                                        params: { id: tag.id },
                                    }}
                                    style={styles.textLink}
                                >
                                    {tag.name || tag.identifier}
                                </Link>
                                {index !== customer.tags.data.length - 1 ? (
                                    <Text>, </Text>
                                ) : null}
                            </React.Fragment>
                        ))
                    ) : (
                        <Text>
                            {t("backoffice.account.no_tags", "No tags")}
                        </Text>
                    )}
                    {customer.tags.data.length > 10 ? (
                        <Text style={styles.textLink} onPress={onAssignTags}>
                            ... {t("backoffice.customer.more_tags", "See more")}
                        </Text>
                    ) : null}
                </View>
            </View>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    firstRow: {
        borderTopWidth: 0,
    },
    row: {
        flexDirection: "row",
        borderTopColor: theme.colors.grey100,
        borderTopWidth: 1,
    },
    labelHolder: {
        flexBasis: 120,
        marginBottom: theme.spacingScale / 2,
    },
    labelText: {
        fontWeight: "bold",
    },
    valueHolder: {
        flex: 5,
        flexDirection: "row",
        flexWrap: "wrap",
    },
    textLink: {
        ...theme.styles.link,
    },
});

import { ReactElement, useCallback, useState } from "react";
import {
    Button,
    ButtonSizes,
    SearchForm,
    StyleFunction,
    useForm,
    useThemedStyle,
} from "../../..";
import { SearchDefinition, buildQueryForSearch, SearchQuery } from "lib";
import { useTranslation } from "locales";

type SearchOptions = {
    visible: boolean;
    hideCloseButton: boolean;
    buttonSize: ButtonSizes;
    onSubmit: () => void;
};

export function useSearch(
    definition: SearchDefinition,
    options?: Partial<SearchOptions>
): {
    component: ReactElement | null;
    indicator: ReactElement | null;
    search: any;
    values: SearchDefinition | null;
    setValue: <Field extends string | number>(
        field: Field,
        value: SearchDefinition[Field]
    ) => void;
} {
    const [visible, setVisible] = useState(options?.visible || false);
    const [search, setSearch] = useState<SearchQuery>({});

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const form = useForm<SearchDefinition>({}, definition);
    const [{ values }, { setValue }] = form;

    const onSearch = useCallback(async () => {
        if (!values) {
            return;
        }

        const query = buildQueryForSearch(values);

        setSearch(query);

        if (options?.onSubmit && typeof options.onSubmit === "function") {
            options.onSubmit();
        }
    }, [options, values]);

    const handleToggleSearch = () => setVisible(!visible);

    const handleCloseSearch = () => setVisible(false);

    return {
        component: SearchForm({
            form,
            onSubmit: onSearch,
            onCloseSearch: handleCloseSearch,
            visible,
            hideCloseButton: options?.hideCloseButton || false,
        }),
        indicator: Button({
            iconName: visible ? "search-minus" : "search-plus",
            children: t("common.search", "Søg"),
            variant: "outline",
            onPress: handleToggleSearch,
            style: [
                styles.searchIcon,
                visible ? styles.searchActive : styles.searchInactive,
            ],
            size: options?.buttonSize || "normal",
        }),
        search,
        values,
        setValue,
    };
}

const styleFunc: StyleFunction = theme => ({
    searchIcon: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },

    searchActive: {
        color: theme.colors.grey250,
    },

    searchInactive: {
        color: theme.colors.primary,
    },
});

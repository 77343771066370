import { ReportDefinition, ReportTypes } from "./types";

export const hourSalesDefinition: ReportDefinition = {
    translationKey: "report.hour_sales.name",
    reportType: ReportTypes.HOUR_SALES,
    primaryTable: "rm_invoice_payment",
    joins: {
        department: {
            sql: "LEFT JOIN department ON rm_invoice.department_id = department.id",
            dependOn: ["invoice"],
        },
        invoice: {
            sql: "JOIN rm_invoice ON rm_invoice.id = rm_invoice_payment.invoice_id",
        },
        cash_register: {
            sql: "JOIN cash_register ON cash_register.id = rm_invoice.cash_register_id",
            dependOn: ["invoice"],
        },
        user: {
            sql: 'JOIN "user" ON "user".id = rm_invoice.user_id',
            dependOn: ["invoice"],
        },
    },
    fields: {
        sale_hour: {
            translationKey: "report.columns.sale_hour",
            type: "CUSTOM",
            content:
                "CONCAT(\n" +
                "   to_char(rm_invoice.created_at::TIMESTAMPTZ AT TIME ZONE {{time_zone}}, 'HH24'), ':00-',\n" +
                "   to_char(rm_invoice.created_at::TIMESTAMPTZ AT TIME ZONE {{time_zone}} + interval '1 hour', 'HH24'), ':00')",
            selectedByDefault: true,
            required: true,
            dependOnJoin: ["invoice"],
        },
        department_name: {
            translationKey: "report.columns.department_name",
            type: "SIMPLE",
            field: { table: "department", column: "name" },
            selectedByDefault: true,
            dependOnJoin: ["department"],
            default: "No department",
        },
        transactions: {
            translationKey: "report.columns.transactions",
            type: "COUNT",
            field: {
                table: "rm_invoice_payment",
                column: "id",
            },
            selectedByDefault: true,
            formatAs: "QUANTITY",
        },
        total: {
            translationKey: "report.columns.total_amount",
            type: "SUM_AMOUNT",
            field: {
                table: "rm_invoice_payment",
                column: "amount",
            },
            selectedByDefault: true,
            formatAs: "AMOUNT",
        },
        cash_register_name: {
            translationKey: "report.columns.cash_register",
            type: "SIMPLE",
            field: { table: "cash_register", column: "name" },
            selectedByDefault: false,
            dependOnJoin: ["cash_register"],
        },
        user_name: {
            translationKey: "report.columns.user_name",
            type: "SIMPLE",
            field: { table: "user", column: "username" },
            selectedByDefault: false,
            dependOnJoin: ["user"],
        },
    },
    filters: {
        cash_register: {
            field: { table: "cash_register", column: "id" },
            translationKey: "report.columns.cash_register",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["cash_register"],
        },
        department: {
            field: { table: "department", column: "id" },
            translationKey: "report.columns.department",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["department"],
        },
        merchant_id: {
            field: { table: "rm_invoice", column: "merchant_id" },
            translationKey: "report.columns.merchant_id",
            type: "EQUALS",
            internalUse: true,
        },
        report_date: {
            field: { table: "rm_invoice", column: "created_at" },
            translationKey: "report.columns.report_date",
            type: "BETWEEN",
        },
        state: {
            translationKey: "report.columns.state",
            type: "CUSTOM",
            content: "rm_invoice_payment.state = 'SUCCESSFUL'",
            internalUse: true,
        },
        user: {
            field: { table: "user", column: "id" },
            translationKey: "report.columns.user_name",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["user"],
        },
    },
    grouping: {
        cash_register: {
            translationKey: "report.columns.cash_register",
            type: "CASH_REGISTER",
            dependOnJoin: ["cash_register"],
        },
        department: {
            translationKey: "report.columns.department",
            type: "DEPARTMENT",
            dependOnJoin: ["department"],
        },
        user_name: {
            translationKey: "report.columns.user_name",
            type: "USER",
            dependOnJoin: ["user"],
        },
        sale_hour: {
            translationKey: "report.columns.sale_hour",
            type: "LABEL",
            internalUse: true,
        },
        year: {
            translationKey: "report.columns.year",
            type: "YEAR",
            field: { table: "rm_invoice", column: "created_at" },
        },
        month: {
            translationKey: "report.columns.month",
            type: "MONTH",
            field: { table: "rm_invoice", column: "created_at" },
        },
        day: {
            translationKey: "report.columns.day",
            type: "DAY",
            field: { table: "rm_invoice", column: "created_at" },
        },
        hour: {
            translationKey: "report.columns.hour",
            type: "HOUR",
            field: { table: "rm_invoice", column: "created_at" },
        },
        days: {
            translationKey: "",
            type: "CUSTOM",
            content: "DATE_TRUNC( 'day', rm_invoice.created_at)",
            internalUse: true,
        },
    },
    ordering: {
        days: {
            translationKey: "report.columns.days",
            type: "CUSTOM",
            content: "DATE_TRUNC( 'day', rm_invoice.created_at)",
            internalUse: true,
        },
        department_name: {
            translationKey: "report.columns.department_name",
            type: "CUSTOM",
            content: "department_name",
        },
        sale_hour: {
            translationKey: "report.columns.sale_hour",
            type: "CUSTOM",
            content: "sale_hour",
        },
    },
};

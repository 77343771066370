import React, { useCallback, useState } from "react";
import { RequestTokenForm } from "./request-token-form";
import {
    Spacer,
    StyleFunction,
    Surface,
    Text,
    ValidationSchema,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import { Image, View } from "react-native";
import { useTranslation } from "locales";
import {
    GQGetSimpleUserQuery,
    GQGetSimpleUserQueryVariables,
    GetSimpleUserDocument,
    usePasswordResetTokenCreateMutation,
} from "graphql-sdk";
import { useApolloClient } from "@apollo/client";

export type ResetPasswordData = {
    email: string;
};

const resetPasswordSchema: ValidationSchema<ResetPasswordData> = {
    email: { required: true },
};

const defaultResetPasswordData = {
    email: "",
};

export function RequestToken() {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const logo = require("../../assets/img/vp-logo.png");
    const form = useForm<ResetPasswordData>(
        resetPasswordSchema,
        defaultResetPasswordData
    );
    const [{ values }] = form;
    const [passwordResetTokenCreate] = usePasswordResetTokenCreateMutation();
    const gqlClient = useApolloClient();
    const [showStatusMessage, setShowStatusMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const handleEmailSubmit = useCallback(async () => {
        if (!values) {
            return;
        }

        const email = values.email;

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setShowErrorMessage(true);
            return;
        }

        const user = await gqlClient.query<
            GQGetSimpleUserQuery,
            GQGetSimpleUserQueryVariables
        >({
            query: GetSimpleUserDocument,
            variables: {
                username: email,
            },
        });
        if (user.data.getSimpleUser) {
            await passwordResetTokenCreate({
                variables: {
                    passwordResetToken: {
                        userId: user.data.getSimpleUser.id,
                    },
                },
            });
            setShowStatusMessage(true);
        } else {
            setShowStatusMessage(false);
        }
    }, [values, gqlClient, passwordResetTokenCreate]);

    return (
        <View style={styles.container}>
            <Image source={logo} style={styles.logo} />
            <Spacer space={4} />
            <Surface style={styles.box}>
                <View>
                    <Text>
                        <h1>
                            {t(
                                "backoffice.password_form.forgotten_password",
                                "Forgot your password?"
                            )}
                        </h1>
                    </Text>
                </View>
                {showStatusMessage ? (
                    <Text>
                        {t(
                            "backoffice.password_form.forgotten_password_confirmation",
                            "If your email is found in our user database, you will soon receive an email with further information."
                        )}
                    </Text>
                ) : (
                    <View>
                        <Text style={styles.text}>
                            {t(
                                "backoffice.password_form.forgotten_password_explanation",
                                "Do not despair!\nSubmit your email below and we will send you a link to reset your password."
                            )}
                        </Text>

                        {showErrorMessage && (
                            <Text style={styles.error}>
                                {t(
                                    "backoffice.password_form.invalid_email_error",
                                    "You have entered an invalid email address. Please try again."
                                )}
                            </Text>
                        )}

                        <RequestTokenForm
                            form={form}
                            handleSubmit={handleEmailSubmit}
                        />
                    </View>
                )}
            </Surface>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.colors.grey50,
    },
    logo: {
        width: 252,
        height: 64,
    },
    box: {
        elevation: 4,
        backgroundColor: theme.colors.white,
        width: 600,
        padding: theme.spacingScale * 4,
    },
    text: {
        marginBottom: theme.spacingScale,
    },
    error: {
        color: theme.colors.error,
        marginBottom: theme.spacingScale,
    },
});

import { StyleSheet } from "react-native";
import { theme } from "@venuepos/react-common/src/theme/themes/venue-default";

export const selectStyles = {
    // Need to raise zIndex otherwise the dropdown wont be on top
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    control: (baseStyles, _) => ({
        ...baseStyles,
        paddingLeft: 6,
        paddingRight: 6,
        borderRadius: theme.borderRadiusSmall,
        backgroundColor: theme.colors.white,
        width: "100%",
        borderWidth: StyleSheet.hairlineWidth,
        marginBottom: theme.spacingScale,
        minHeight: 40,
        borderColor: theme.colors.secondary,
    }),
    // Styling of the chip
    multiValue: (base, _) => ({
        ...base,
        borderStyle: "solid",
        borderRadius: 16,
        borderColor: theme.colors.grey250,
        backgroundColor: theme.colors.white,
        borderWidth: 1.5,
    }),
    // Style the text in chip
    multiValueLabel: base => ({
        ...base,
        color: theme.colors.grey800,
    }),
    // Remove the hover effect on the remove icon
    multiValueRemove: base => ({
        ...base,
        color: theme.colors.grey250,
        ":hover": {},
    }),
};

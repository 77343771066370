import React, { ReactNode } from "react";
import { View } from "react-native";
import { IconButton } from "../icon-button";
import { useThemedStyle, StyleFunction } from "../../theme";

export function RemovableRow({
    children,
    onRemove,
    disabled,
}: {
    children: ReactNode;
    onRemove: () => void;
    disabled?: boolean;
}) {
    const styles = useThemedStyle(styleFunc);

    return (
        <View style={styles.row}>
            {children}
            {!disabled && (
                <IconButton
                    name="close"
                    color={styles.removeIcon.color}
                    onPress={onRemove}
                    style={styles.removeIcon}
                />
            )}
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    row: {
        flexDirection: "row",
    },
    removeIcon: {
        color: theme.colors.textDark,
        marginTop: 2,
        marginLeft: 10,
    },
});

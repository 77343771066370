/* istanbul ignore file */
import { RootStackParamList } from "../../navigation";

export * from "./form";

/**
 * A set of constants to provide easier navigation in the Entity Config Form.
 * See an example of use in /app/screens/admin/merchant/merchant-edit-screen.tsx
 */
export const VALUE_CREATORS: Record<
    string,
    Record<"route", keyof RootStackParamList>
> = {
    buttonLayout: {
        route: "LAYOUT_CREATE",
    },
    tableLayout: {
        route: "TABLE_LAYOUT_CREATE",
    },
    defaultDepartment: {
        route: "DEPARTMENT_CREATE",
    },
    paymentOptions: {
        route: "PAYMENT_CONFIGURATION_CREATE",
    },
    defaultVat: {
        route: "VAT_CREATE",
    },
    tagGroup: {
        route: "TAG_GROUP_CREATE",
    },
};

export const VALUE_EDITORS: Record<
    string,
    Record<"route", keyof RootStackParamList>
> = {
    buttonLayout: {
        route: "LAYOUT_EDIT",
    },
    tableLayout: {
        route: "TABLE_LAYOUT_EDIT",
    },
    defaultDepartment: {
        route: "DEPARTMENT_EDIT",
    },
    paymentOptions: {
        route: "PAYMENT_CONFIGURATION_EDIT",
    },
    tagGroup: {
        route: "TAG_GROUP_EDIT",
    },
};

import React, { createContext } from "react";
import { Provider as PaperProvider, MD3LightTheme } from "react-native-paper";
import { ITheme } from "./types";

import { theme as defaultTheme } from "./themes/venue-default";
import { View, StyleSheet } from "react-native";

// Required to make React Native paper work with TypeScript
declare global {
    namespace ReactNativePaper {
        interface Theme extends ITheme {}
    }
}

// Eventually we want the actual theme data moved into their own files.

export const currentTheme: ITheme = {
    ...MD3LightTheme,
    ...defaultTheme,
    fonts: { ...MD3LightTheme.fonts, ...defaultTheme.fonts },
};

export const ThemeContext = createContext<ITheme | undefined>(undefined);

export function ProvideThemeContext({
    children,
}: {
    children: React.ReactNode;
}) {
    // When we make theme switching possible, then PaperProvider needs to get a consumer of our themeprovider
    // This is not necessary while we have only one theme
    return (
        <ThemeContext.Provider value={currentTheme}>
            <View style={styles.root}>
                <PaperProvider theme={currentTheme}>{children}</PaperProvider>
            </View>
        </ThemeContext.Provider>
    );
}

const styles = StyleSheet.create({
    root: {
        flex: 1,
        overflow: "hidden",
    },
});

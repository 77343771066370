import { DrawerNavigationProp } from "@react-navigation/drawer";
import {
    Button,
    Loading,
    ModalWrapper,
    Picker,
    StyleFunction,
    Text,
    useModal,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQRole, useAvailableLoginAsRolesQuery } from "graphql-sdk";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";
import { RootStackParamList } from "../../navigation";

type LoginAsRoleRoleProps = {
    onClose: (val: string | undefined) => void;
    merchantId: string;
    navigation: DrawerNavigationProp<RootStackParamList>;
};

function LoginAsRoleModal(props: LoginAsRoleRoleProps) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const roles = useAvailableLoginAsRolesQuery({
        variables: { merchantId: props.merchantId },
    });
    const [selectedRole, setSelectedRole] = useState<GQRole["id"]>();

    const selectAuthUserRole = useCallback(async () => {
        if (selectedRole === undefined || !roles.data) {
            return;
        }

        // Close the modal.
        props.onClose(selectedRole);
    }, [props, roles.data, selectedRole]);

    useEffect(() => {
        if (
            selectedRole === undefined &&
            roles.data &&
            roles.data.roles.data.length > 0
        ) {
            setSelectedRole(roles.data.roles.data[0].id);
        }
    }, [roles.data, selectedRole]);

    const roleOptions = useMemo(() => {
        if (!roles || !roles.data || !roles.data.roles) {
            return <Loading />;
        }

        if (roles.data.roles.data.length === 0) {
            return (
                <Text>
                    {t(
                        "merchant.no_available_roles",
                        "There are no available roles for you"
                    )}
                </Text>
            );
        }

        if (roles.data.roles.data.length <= 5) {
            return roles.data.roles.data.map(roleItr => (
                <Button
                    key={roleItr.id}
                    type="primary"
                    iconName="user"
                    size="small"
                    variant="outline"
                    onPress={() => {
                        setSelectedRole(roleItr.id);
                        selectAuthUserRole();
                    }}
                    style={styles.buttonStyle}
                >
                    {roleItr.name}
                </Button>
            ));
        }

        return (
            <>
                <Picker
                    selectedValue={selectedRole}
                    onValueChange={setSelectedRole}
                >
                    {roles.data.roles.data.map(role => (
                        <Picker.Item
                            key={role.id}
                            label={role.name}
                            value={role.id}
                        />
                    ))}
                </Picker>
                <Button type="primary" onPress={selectAuthUserRole}>
                    {t("merchant.sign_in", "Sign in")}
                </Button>
            </>
        );
    }, [roles, selectedRole, selectAuthUserRole, t, styles.buttonStyle]);

    return (
        <ModalWrapper
            onClose={() => props.onClose(undefined)}
            title={t("merchant.login_as", "Login as")}
        >
            <View style={styles.container}>{roleOptions}</View>
        </ModalWrapper>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        minWidth: 300,
        paddingHorizontal: theme.spacingScale * 2,
        paddingVertical: theme.spacingScale,
    },

    buttonStyle: {
        marginBottom: theme.spacingScale,
    },
});

export function useLoginAsRole() {
    const { render } = useModal();
    return (props: Omit<LoginAsRoleRoleProps, "onClose">) =>
        render(onClose => <LoginAsRoleModal {...props} onClose={onClose} />);
}

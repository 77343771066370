import {
    ColorIndicator,
    ColorIndicatorStatus,
    DataTable,
    Loading,
    PageTitle,
    StyleFunction,
    Surface,
    usePagination,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQLatestTaskResultsQuery,
    useLatestTaskResultsQuery,
} from "graphql-sdk";
import { formatDateTime } from "lib";
import { AvailableLocale } from "locales";
import React, { useCallback } from "react";
import { useTranslation } from "locales";
import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";

type ScreenProps = RootStackScreenProps<"TASK_RESULT_LIST">;

export function TaskResultListScreen({
    navigation: { navigate },
}: ScreenProps) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [{ locale }] = useAdminSession(["locale"]);

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "created_at",
        initialSortDirection: "DESC",
    });

    const { data, loading } = useLatestTaskResultsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
        },
        fetchPolicy: "no-cache",
    });

    const handleShowTaskResult = useCallback(
        (type: string) => {
            navigate("TASK_RESULT_VIEW", {
                type: type,
            });
        },
        [navigate]
    );

    const renderTaskRow = useCallback(
        (
            item: GQLatestTaskResultsQuery["latestTaskResults"]["data"][0],
            rowIndex
        ) => {
            let status;
            switch (item.status) {
                case "OK":
                    status = "success";
                    break;
                default:
                    status = item.status.toLowerCase();
            }

            return (
                <DataTable.Row
                    style={rowIndex % 2 === 1 ? styles.oddRow : undefined}
                    onPress={() => handleShowTaskResult(item.type)}
                    key={item.jobId}
                >
                    <DataTable.Cell style={styles.smallColumn}>
                        <ColorIndicator
                            size="large"
                            status={status as ColorIndicatorStatus}
                        />
                    </DataTable.Cell>
                    <DataTable.Cell>{item.jobId}</DataTable.Cell>
                    <DataTable.Cell>{item.name}</DataTable.Cell>
                    <DataTable.Cell>{item.type}</DataTable.Cell>
                    <DataTable.Cell numeric style={styles.mediumColumn}>
                        {`${(item.executionTime / 1000).toFixed(3)} ms`}
                    </DataTable.Cell>
                    <DataTable.Cell numeric style={styles.largeColumn}>
                        {formatDateTime(
                            item.createdAt,
                            locale as AvailableLocale
                        )}
                    </DataTable.Cell>
                </DataTable.Row>
            );
        },
        [
            handleShowTaskResult,
            locale,
            styles.largeColumn,
            styles.mediumColumn,
            styles.oddRow,
            styles.smallColumn,
        ]
    );

    return (
        <AdminContainer>
            <PageTitle>
                {t(
                    "backoffice.task_result_list.task_result_list",
                    "Task results"
                )}
            </PageTitle>

            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "status" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("status")}
                            style={styles.smallColumn}
                        >
                            {t("backoffice.task_result.status", "Status")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "jobId" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("jobId")}
                        >
                            {t("backoffice.task_result.job_id", "Job id")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "name" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "type" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("type")}
                        >
                            {t("backoffice.task_result.type", "Type")}
                        </DataTable.Title>
                        <DataTable.Title
                            style={styles.mediumColumn}
                            numeric
                            sortDirection={
                                sortBy === "executionTime"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("executionTime")}
                        >
                            {t(
                                "backoffice.task_result.execution_time",
                                "Execution time"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            style={styles.largeColumn}
                            numeric
                            sortDirection={
                                sortBy === "createdAt"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("createdAt")}
                        >
                            {t("common.created_at", "Created at")}
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : (
                        data?.latestTaskResults.data.map(renderTaskRow)
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.latestTaskResults.pagination.pages}
                        itemCount={
                            data?.latestTaskResults.pagination.resultCount
                        }
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
    smallColumn: {
        flexBasis: 80,
        flexGrow: 0,
        flexShrink: 0,
    },

    mediumColumn: {
        flexBasis: 130,
        flexGrow: 0,
        flexShrink: 0,
    },

    largeColumn: {
        flexBasis: 230,
        flexGrow: 0,
        flexShrink: 0,
    },
});

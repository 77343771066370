import { Link } from "@react-navigation/native";
import {
    CheckBox,
    DataTable,
    IconButton,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import { formatDateTime, tagTypes } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "locales";

import { useAdminSession } from "../../session";

import type { GQTag, GQTagsQuery } from "graphql-sdk";
import type { AvailableLocale } from "locales";
import { StyleProp, ViewStyle } from "react-native";
export function TagItem({
    item,
    selectedTag,
    onSelect,
    onMultiSelectChange,
    onDelete,
    style,
}: {
    item: GQTagsQuery["tags"]["data"][0];
    selectedTag: boolean;
    onSelect: () => void;
    onMultiSelectChange: (selected: boolean, itemIds: GQTag["id"][]) => void;
    onDelete: () => void;
    style?: StyleProp<ViewStyle>;
}) {
    const [{ locale }] = useAdminSession(["locale"]);
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const handleMultiSelectChange = useCallback(
        value => onMultiSelectChange(value, [item.id]),
        [item.id, onMultiSelectChange]
    );

    const tagTypeLabel = tagTypes.find(
        tagType => item.tagType === tagType.value
    )?.label;

    return (
        <DataTable.Row testID={`tag:item:${item.name}`} style={style}>
            <DataTable.Cell style={styles.iconColumn}>
                <CheckBox
                    value={selectedTag}
                    onValueChange={handleMultiSelectChange}
                    style={styles.checkbox}
                    testID={`check:${item.name}`}
                />
            </DataTable.Cell>
            <DataTable.Cell testID="tag:name">
                <Link
                    to={{
                        screen: "TAG_EDIT",
                        params: { id: item.id },
                    }}
                    style={styles.textLink}
                    numberOfLines={2}
                >
                    {item.name || item.identifier}
                </Link>
            </DataTable.Cell>
            <DataTable.Cell testID="tag:type">
                {tagTypeLabel
                    ? t(tagTypeLabel, item.tagType)
                    : t("common.unknown", "Unknown")}
            </DataTable.Cell>
            <DataTable.Cell testID="tag:groupName">
                {item.group && (
                    <Link
                        to={{
                            screen: "TAG_GROUP_EDIT",
                            params: { id: item.group?.id },
                        }}
                        style={styles.textLink}
                        numberOfLines={2}
                    >
                        {item.group?.name}
                    </Link>
                )}
            </DataTable.Cell>
            <DataTable.Cell testID="tag:account">
                {item.account && (
                    <Link
                        to={{
                            screen: "ACCOUNT_VIEW",
                            params: { id: item.account.id },
                        }}
                        style={[
                            styles.textLink,
                            !item.account.name ? styles.noName : undefined,
                        ]}
                        numberOfLines={2}
                    >
                        {item.account.name || t("common.no_name", "No name")}
                    </Link>
                )}
            </DataTable.Cell>
            <DataTable.Cell>
                {item.customer && (
                    <Link
                        to={{
                            screen: "CUSTOMER_VIEW",
                            params: { id: item.customer.id },
                        }}
                        style={styles.textLink}
                        numberOfLines={2}
                    >
                        {item.customer.name}
                    </Link>
                )}
            </DataTable.Cell>
            <DataTable.Cell>
                {formatDateTime(item.createdAt, locale as AvailableLocale)}
            </DataTable.Cell>
            <DataTable.Cell numeric style={styles.sortIconColumn}>
                {!item.account?.id && !item.customer?.id ? (
                    <IconButton
                        name="delete"
                        onPress={onDelete}
                        color={styles.sortIconColumn.color}
                        disabled={!!item.account?.id || !!item.customer?.id}
                    />
                ) : null}
                <IconButton
                    name="edit"
                    onPress={onSelect}
                    color={styles.sortIconColumn.color}
                />
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = theme => ({
    checkbox: {
        marginVertical: 6,
    },
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 40,
        color: theme.colors.secondary,
    },
    sortIconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 100,
        color: theme.colors.secondary,
    },
    textLink: {
        ...theme.styles.link,
    },
    noName: {
        fontStyle: "italic",
    },
});

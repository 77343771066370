import {
    Alert,
    Button,
    ColorIndicator,
    DataTable,
    Icon,
    Loading,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    usePagination,
    useSearch,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { useUsersQuery } from "graphql-sdk";
import { SearchDefinition, formatDateTime } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "locales";
import { FlatList, View } from "react-native";

import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";

import type { AvailableLocale } from "locales";
import { useSearchDefinition } from "../../hooks";
type ScreenProps = RootStackScreenProps<"USERS">;

export function UserListScreen({ navigation: { navigate } }: ScreenProps) {
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const auth = useAuth();
    auth.enforce(
        [
            "merchant.user.write",
            "merchant.user.delete",
            "admin.user.write",
            "admin.user.delete",
        ],
        "OR"
    );

    const [t] = useTranslation();
    const [{ locale }] = useAdminSession(["locale"]);
    const { createSearchDefinition } = useSearchDefinition();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({ initialSortBy: "username" });

    const defaultUserSearch: SearchDefinition = {
        username: {
            name: t("searching.username", "Username"),
            type: "string",
            value: "",
            enabled: false,
        },
        email: {
            name: t("searching.email", "Email"),
            type: "string",
            value: "",
            enabled: false,
        },
        firstname: {
            name: t("searching.firstname", "Firstname"),
            type: "string",
            value: "",
            enabled: false,
        },
        lastname: {
            name: t("searching.lastname", "Lastname"),
            type: "string",
            value: "",
            enabled: false,
        },
        createdAt: createSearchDefinition("createdAt"),
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultUserSearch, {
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    // GraphQL
    const { data, loading, error } = useUsersQuery({
        fetchPolicy: "no-cache",
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
    });

    // Actions
    const handleEdit = useCallback(
        (id: string) => {
            navigate("USER_EDIT", {
                id,
            });
        },
        [navigate]
    );

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <AdminContainer>
            <View style={[theme.styles.row, styles.container]}>
                {auth.may(["merchant.user.write", "admin.user.write"], "OR") ? (
                    <Button
                        onPress={() => {
                            navigate("USER_CREATE");
                        }}
                        style={styles.button}
                    >
                        {t("backoffice.user_list.create_user", "Create")}
                    </Button>
                ) : null}
                <Spacer space={1} />
                {searchIndicator}
            </View>
            <Spacer space={2} />
            <Surface>
                {searchComponent}
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "active" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("active")}
                            style={styles.activeColumn}
                        >
                            {t("backoffice.user.active", "Active")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "username"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("username")}
                        >
                            {t("backoffice.user.username", "Username")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "firstName"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("firstName")}
                        >
                            {t("backoffice.user.firstName", "First name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "lastName"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("lastName")}
                        >
                            {t("backoffice.user.lastName", "Last name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "email" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("email")}
                        >
                            {t("backoffice.user.email", "E-mail")}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t("backoffice.user.role", "Role")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "createdAt"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("createdAt")}
                        >
                            {t("common.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title style={styles.sortColumn}>
                            <Icon name="sort" color={styles.sortColumn.color} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading || !data ? (
                        <Loading />
                    ) : (
                        <FlatList
                            data={data && data.users.data}
                            renderItem={({ item }) => (
                                <DataTable.Row
                                    onPress={() => handleEdit(item.id)}
                                >
                                    <DataTable.Cell style={styles.activeColumn}>
                                        <ColorIndicator
                                            status={
                                                item.active
                                                    ? "success"
                                                    : "warning"
                                            }
                                        />
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                        {item.username}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                        {item.firstName}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                        {item.lastName}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                        {item.email}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                        {item.roles
                                            .map(role => role.role.name)
                                            .sort((roleA, roleB) =>
                                                roleA.localeCompare(roleB)
                                            )
                                            .join(", ")}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                        {formatDateTime(
                                            item.createdAt,
                                            locale as AvailableLocale
                                        )}
                                    </DataTable.Cell>
                                    <DataTable.Cell style={styles.sortColumn} />
                                </DataTable.Row>
                            )}
                            keyExtractor={item => item.id}
                        />
                    )}

                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.users.pagination.pages}
                        itemCount={data?.users.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "flex-end",
        flexWrap: "wrap",
    },
    activeColumn: {
        flexBasis: 60,
        flexGrow: 0,
        flexShrink: 0,
    },
    sortColumn: {
        flexBasis: 40,
        flexShrink: 0,
        flexGrow: 0,
        color: theme.colors.black,
    },
});

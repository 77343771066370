import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { useTranslation } from "locales";
import {
    Button,
    CheckBox,
    DataTable,
    Loading,
    SearchInput,
    StyleFunction,
    usePagination,
    useThemedStyle,
} from "@venuepos/react-common";
import { TagItem } from "./tag-item";
import type { GQTagScreenFieldsFragment } from "graphql-sdk";
import type { ITag } from "lib";
import type {
    AssignTagsModalOutput,
    AssignTagsModalProps,
    TagFetchResult,
} from "./assign-tags-modal";
import { useMultiSelect } from "../../../hooks/use-multi-select";

export function TagsFindTab({
    onClose,
    tagsFindQuery,
    enabledTags,
}: {
    onClose: (tags?: AssignTagsModalOutput) => void;
    tagsFindQuery: AssignTagsModalProps["tagsFindQuery"];
    enabledTags: ITag["id"][];
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
        initialPageSize: 10,
    });

    const {
        selectedItems: selectedTags,
        handleMultiSelect,
        allItemsSelected: allRowsSelected,
        setAllItemsSelected: setAllRowsSelected,
    } = useMultiSelect<GQTagScreenFieldsFragment["id"]>();

    const [search, setSearch] = useState<string>("");

    const renderTagRow = useCallback(
        (item: GQTagScreenFieldsFragment) => (
            <TagItem
                key={item.id}
                item={item}
                selectedTag={selectedTags.includes(item.id)}
                onMultiSelectChange={handleMultiSelect}
            />
        ),
        [selectedTags, handleMultiSelect]
    );

    const [fetchResult, setFetchResult] = useState<TagFetchResult>();

    useEffect(() => {
        tagsFindQuery({
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            search: {
                query: search,
            },
        }).then(result => setFetchResult(result));
    }, [page, pageSize, search, sortBy, sortDirection, tagsFindQuery]);

    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list
            onPageChange(0);
        },
        [onPageChange]
    );

    if (!fetchResult) {
        return <Loading />;
    }

    const { data, loading, pagination } = fetchResult;
    return (
        <View>
            <SearchInput onChange={handleSearchTextChange} testID="tagSearch" />
            <DataTable>
                <DataTable.Header>
                    <DataTable.Title style={styles.iconColumn}>
                        <CheckBox
                            value={allRowsSelected}
                            onValueChange={value => {
                                const allItemIds = data.map(p => p.id);
                                handleMultiSelect(value, allItemIds);
                                setAllRowsSelected(value);
                            }}
                        />
                    </DataTable.Title>
                    <DataTable.Title
                        sortDirection={
                            sortBy === "name" ? sortDirection : undefined
                        }
                        onPress={() => onSortChange("name")}
                    >
                        {t("common.name", "Name")}
                    </DataTable.Title>
                    <DataTable.Title
                        sortDirection={
                            sortBy === "groupName" ? sortDirection : undefined
                        }
                        onPress={() => onSortChange("groupName")}
                    >
                        {t("common.group", "Group")}
                    </DataTable.Title>
                    <DataTable.Title
                        sortDirection={
                            sortBy === "tagType" ? sortDirection : undefined
                        }
                        onPress={() => onSortChange("tagType")}
                    >
                        {t("common.type", "Type")}
                    </DataTable.Title>
                    <DataTable.Title
                        sortDirection={
                            sortBy === "identifier" ? sortDirection : undefined
                        }
                        onPress={() => onSortChange("identifier")}
                    >
                        {t("backoffice.account.tag", "Tag")}
                    </DataTable.Title>
                </DataTable.Header>
                {(loading && <Loading />) || data.map(renderTagRow)}
                <DataTable.Pagination
                    onPageChange={onPageChange}
                    pageSize={pageSize}
                    onSizeChange={onPageSizeChange}
                    page={page}
                    numberOfPages={pagination.pages}
                    itemCount={pagination.resultCount}
                    disablePageSizeSelector={true}
                />
            </DataTable>
            <View style={styles.buttonContainer}>
                <Button
                    style={styles.button}
                    onPress={() => {
                        onClose(selectedTags.concat(enabledTags));
                    }}
                    testID="assignTag:save"
                >
                    {t("common.save", "Save")}
                </Button>
            </View>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    typeIcon: {
        color: theme.colors.primary,
    },
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 40,
    },
});

import type { SortDirection } from "@venuepos/react-common";
import {
    DataTable,
    Icon,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import { useTranslation } from "locales";
import React from "react";

export function TransactionTableHeader({
    onSortChange,
    sortBy,
    sortDirection,
}: {
    onSortChange: (field: string) => void;
    sortBy: string | undefined;
    sortDirection: SortDirection;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    return (
        <DataTable.Header>
            <DataTable.Title
                sortDirection={
                    sortBy === "transactionAt" ? sortDirection : undefined
                }
                onPress={() => onSortChange("transactionAt")}
            >
                {t("common.created_at", "Created at")}
            </DataTable.Title>
            <DataTable.Title>
                {t("backoffice.account.transaction_type")}
            </DataTable.Title>
            <DataTable.Title
                numeric
                sortDirection={sortBy === "amount" ? sortDirection : undefined}
                onPress={() => onSortChange("amount")}
            >
                {t("common.amount", "Amount")}
            </DataTable.Title>
            <DataTable.Title numeric style={styles.iconColumn}>
                <Icon name="sort" color={styles.iconColumn.color} />
            </DataTable.Title>
        </DataTable.Header>
    );
}

const styleFunc: StyleFunction = theme => ({
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 100,
        color: theme.colors.black,
    },
});

import React from "react";
import {
    Alert,
    Headline,
    Loading,
    Surface,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQInvoiceAmountPerMonth,
    useDashboardInvoiceAmountPerMonthStatsQuery,
} from "graphql-sdk";
import { formatAmount } from "lib";
import { useTranslation } from "locales";
import { View } from "react-native";
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryGroup,
    VictoryLegend,
    VictoryTheme,
    VictoryTooltip,
} from "victory";
import { useAdminSession } from "../../../session";
import { dashboardStyleFunc } from "../styles";

export function InvoicesPerMonthStats() {
    const [t] = useTranslation();
    const sharedStyles = useThemedStyle(dashboardStyleFunc);
    const [{ locale }] = useAdminSession(["locale"]);

    const { data, loading, error } =
        useDashboardInvoiceAmountPerMonthStatsQuery();

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error.message,
                    }
                )}
            </Alert>
        );
    }

    // Index data by their year
    const amountData = data?.dashboardInvoiceAmountPerMonthStats?.reduce(
        (
            acc: {
                year: string;
                data: Array<GQInvoiceAmountPerMonth>;
            }[],
            cur
        ) => {
            const yearArray = acc.find(itr => itr.year === cur.year);
            if (yearArray === undefined) {
                acc.push({
                    year: cur.year,
                    data: [
                        {
                            year: cur.year,
                            monthNumber: cur.monthNumber,
                            sum: cur.sum,
                        },
                    ],
                });
            } else {
                yearArray.data.push({
                    year: cur.year,
                    monthNumber: cur.monthNumber,
                    sum: cur.sum,
                });
            }
            return acc;
        },
        []
    );

    return (
        <Surface style={sharedStyles.widget}>
            <View>
                <Headline testID="headline:invoiceAmountPerMonthStats">
                    {t(
                        "dashboard.invoice_amount_per_month_stat",
                        "Amount per month"
                    )}
                </Headline>
            </View>
            <VictoryChart
                height={300}
                width={1200}
                theme={VictoryTheme.material}
                // Default padding is 50 but we need more for left for large amount values
                padding={{ bottom: 50, top: 50, left: 90 }}
            >
                <VictoryLegend
                    x={95}
                    y={0}
                    title={t("common.year", "Year")}
                    colorScale={"qualitative"}
                    centerTitle
                    orientation="horizontal"
                    gutter={20}
                    style={{
                        border: { stroke: "black" },
                        title: { fontSize: 16 },
                    }}
                    data={amountData?.map(itr => {
                        return { name: itr.year };
                    })}
                />
                <VictoryAxis
                    dependentAxis
                    crossAxis={false}
                    tickFormat={tick => `${formatAmount(tick)}`}
                />
                <VictoryAxis
                    tickFormat={tick => {
                        const date = new Date();
                        date.setMonth(tick - 1);
                        return date.toLocaleString(locale, { month: "long" });
                    }}
                />
                <VictoryGroup offset={20} colorScale={"qualitative"}>
                    {amountData?.map(itr => (
                        <VictoryBar
                            data={itr.data}
                            x="monthNumber"
                            y={d => d.sum}
                            labels={({ datum }) => formatAmount(datum.sum)}
                            labelComponent={<VictoryTooltip />}
                            key={`amount_date_${itr.year}`}
                        />
                    ))}
                </VictoryGroup>
            </VictoryChart>
        </Surface>
    );
}

export * from "@sentry/react";
import { browserTracingIntegration } from "@sentry/react";

export const routingInstrumentation = null;

export function Tracing() {
    return browserTracingIntegration({
        tracingOrigins: ["localhost", /venuepos\.net/],
    });
}

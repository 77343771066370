// Shared Error Constants

export const ERR_NOT_FOUND = "error/not-found";
export const ERR_AUTH_FAILED = "error/auth-failed";
export const ERR_EVENT_STORE_DISPATCH_FAILED =
    "error/event-store-dispatch-failed";
export const ERR_INVALID_AUTH_TOKEN = "error/invalid-auth-token";
export const ERR_INVALID_API_KEY = "error/invalid-api-key";
export const ERR_INVALID_ARGUMENTS = "error/invalid-arguments";
export const ERR_CONFLICT = "error/conflict";
export const ERR_INTERNAL_SERVER_ERROR = "error/internal-server-error";

import React, { ErrorInfo } from "react";
import { View } from "react-native";
import { StyleFunction, useThemedStyle } from "../../theme";
import { Button } from "../button";
import { Icon } from "../icon";
import { captureError } from "../../hooks";
import { Text } from "../text";

type Props = {
    title: string;
    description: string;
    onPress: () => void;
    buttonText: string;
    abort?: (error: Error) => void;
    children?: React.ReactNode;
};

type States = {
    hasError: boolean;
};

export class ErrorBoundary extends React.Component<Props, States> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        captureError(error); // Error Logger
        console.log(error, errorInfo);
    }

    abort(error: Error) {
        captureError(error); // Error Logger
        this.setState({
            hasError: true,
        });
    }

    render() {
        if (this.state.hasError) {
            return <ErrorBoundaryScreen {...this.props} />;
        }

        return this.props.children;
    }
}

function ErrorBoundaryScreen(props: Props) {
    const styles = useThemedStyle(styleFunc);
    return (
        <View style={styles.container}>
            <Icon
                name="error"
                color={styles.icon.color}
                style={styles.icon}
                size="placeholder"
            />
            <Text style={styles.bigText}>{props.title}</Text>
            <Text style={styles.description}>{props.description}</Text>
            <Button
                style={styles.buttonStyle}
                textStyle={styles.buttonText}
                size="huge"
                variant="invert"
                onPress={props.onPress}
            >
                {props.buttonText}
            </Button>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.colors.white,
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacingScale * 2,
    },

    icon: {
        color: theme.colors.error,
        marginBottom: theme.spacingScale * 2,
    },

    bigText: {
        fontSize: 30,
        color: theme.colors.error,
    },

    description: {
        fontSize: 20,
        color: theme.colors.error,
        textAlign: "center",
    },

    buttonStyle: {
        backgroundColor: theme.colors.error,
        marginTop: theme.spacingScale * 4,
    },

    buttonText: {
        color: theme.colors.white,
    },
});

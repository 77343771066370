import {
    ColorIndicator,
    ColorIndicatorStatus,
    DataTable,
    Icon,
    Loading,
    PageTitle,
    StyleFunction,
    Surface,
    Text,
    useModal,
    usePagination,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQTaskResultsQuery, useTaskResultsQuery } from "graphql-sdk";
import { formatDateTime } from "lib";
import { AvailableLocale } from "locales";
import React, { useCallback } from "react";
import { useTranslation } from "locales";
import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";
import { TaskResultOutput } from "./task-result-output";

type ScreenProps = RootStackScreenProps<"TASK_RESULT_VIEW">;

export function TaskResultScreen({ route }: ScreenProps) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [{ locale }] = useAdminSession(["locale"]);
    const { render } = useModal();

    const type = route.params.type;

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "created_at",
        initialSortDirection: "DESC",
    });

    const { data, loading } = useTaskResultsQuery({
        variables: {
            type: type,
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
        },
        fetchPolicy: "no-cache",
    });

    const handleOutputPress = useCallback(
        async item => {
            await render(onClose => (
                <TaskResultOutput onClose={onClose} output={item.output} />
            ));
        },
        [render]
    );

    const renderTaskRow = useCallback(
        (item: GQTaskResultsQuery["taskResults"]["data"][0], rowIndex) => {
            let status;
            switch (item.status) {
                case "OK":
                    status = "success";
                    break;
                default:
                    status = item.status.toLowerCase();
            }

            return (
                <DataTable.Row
                    style={rowIndex % 2 === 1 ? styles.oddRow : undefined}
                    key={item.jobId}
                >
                    <DataTable.Cell style={styles.smallColumn}>
                        <ColorIndicator
                            size="large"
                            status={status as ColorIndicatorStatus}
                        />
                    </DataTable.Cell>
                    <DataTable.Cell style={styles.mediumColumn}>
                        {item.jobId}
                    </DataTable.Cell>
                    <DataTable.Cell onPress={() => handleOutputPress(item)}>
                        {item.output && (
                            <>
                                <Text numberOfLines={1}>{item.output}</Text>
                                <Icon name="eye" style={styles.icon} />
                            </>
                        )}
                    </DataTable.Cell>
                    <DataTable.Cell numeric style={styles.mediumColumn}>
                        {`${(item.executionTime / 1000).toFixed(3)} ms`}
                    </DataTable.Cell>
                    <DataTable.Cell numeric style={styles.largeColumn}>
                        {formatDateTime(
                            item.createdAt,
                            locale as AvailableLocale
                        )}
                    </DataTable.Cell>
                </DataTable.Row>
            );
        },
        [
            handleOutputPress,
            locale,
            styles.icon,
            styles.largeColumn,
            styles.mediumColumn,
            styles.oddRow,
            styles.smallColumn,
        ]
    );

    return (
        <AdminContainer>
            <PageTitle>
                {t(
                    "backoffice.task_result_list.task_result",
                    "Task result ({{type}})",
                    { type: type }
                )}
            </PageTitle>
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "status" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("status")}
                            style={styles.smallColumn}
                        >
                            {t("backoffice.task_result.status", "Status")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "jobId" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("jobId")}
                            style={styles.mediumColumn}
                        >
                            {t("backoffice.task_result.job_id", "Job id")}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t("backoffice.task_result.output", "Output")}
                        </DataTable.Title>
                        <DataTable.Title
                            numeric
                            sortDirection={
                                sortBy === "executionTime"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("executionTime")}
                            style={styles.mediumColumn}
                        >
                            {t(
                                "backoffice.task_result.execution_time",
                                "Execution time"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            numeric
                            sortDirection={
                                sortBy === "createdAt"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("createdAt")}
                            style={styles.largeColumn}
                        >
                            {t("common.created_at", "Created at")}
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : (
                        data?.taskResults.data.map(renderTaskRow)
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.taskResults.pagination.pages}
                        itemCount={data?.taskResults.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
    icon: {
        color: theme.colors.secondary,
        paddingHorizontal: theme.spacingScale / 2,
    },
    smallColumn: {
        flexBasis: 80,
        flexGrow: 0,
        flexShrink: 0,
    },

    mediumColumn: {
        flexBasis: 130,
        flexGrow: 0,
        flexShrink: 0,
    },

    largeColumn: {
        flexBasis: 230,
        flexGrow: 0,
        flexShrink: 0,
    },
});

// Inspiration: https://github.com/callstack/react-native-paper/blob/master/src/components/IconButton.tsx

import React from "react";
import {
    StyleProp,
    ViewStyle,
    StyleSheet,
    TextStyle,
    Pressable,
} from "react-native";
import { useTheme } from "../../theme";
import { Icon, IconProps } from "../icon";
import { Tooltip } from "react-native-paper";

type IconButtonProps = IconProps & {
    disabled?: boolean;
    onPress: () => void;
    tooltip?: string;
    style?: StyleProp<ViewStyle | TextStyle>;
};

export function IconButton({
    name,
    color,
    size,
    variant,
    disabled,
    style,
    onPress,
    tooltip,
    testID,
}: IconButtonProps) {
    const { colors } = useTheme();

    const icon = (
        <Icon
            name={name}
            size={size}
            color={
                StyleSheet.flatten(style as TextStyle)?.color ||
                (disabled ? colors.grey500 : color)
            }
            variant={variant}
        />
    );

    return (
        <Pressable
            onPress={onPress}
            style={[styles.container, style]}
            disabled={disabled}
            testID={testID}
        >
            {tooltip ? (
                <Tooltip title={tooltip} leaveTouchDelay={0}>
                    {icon}
                </Tooltip>
            ) : (
                icon
            )}
        </Pressable>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 6,
    },
});

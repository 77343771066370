import type { FetchResult } from "@apollo/client";
import { StackScreenProps } from "@react-navigation/stack";
import {
    LoadingScreen,
    Surface,
    useAuth,
    useForm,
} from "@venuepos/react-common";
import {
    GQPaymentConfigurationCreateMutation,
    usePaymentConfigurationCreateMutation,
    usePaymentConfigurationFormQuery,
} from "graphql-sdk";
import { IPaymentConfigurationInput, schemaPaymentConfiguration } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "locales";
import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { RootStackParamList } from "../../navigation";
import { defaultPaymentConfiguration } from "./forms";
import { PaymentConfigurationForm } from "./payment-configuration-form";
import { PaymentConfigurationScreen } from "./payment-configuration-screen";

type ScreenProps = StackScreenProps<
    RootStackParamList,
    "PAYMENT_CONFIGURATION_CREATE"
>;

export function PaymentConfigurationCreateScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.payment_configuration.write");

    const [t] = useTranslation();
    const formData = usePaymentConfigurationFormQuery();
    const [paymentConfigurationCreate] =
        usePaymentConfigurationCreateMutation();
    const { handleMutationError } = useHandleMutationError();

    const form = useForm<IPaymentConfigurationInput>(
        schemaPaymentConfiguration,
        defaultPaymentConfiguration
    );
    const [{ values }, { reset }] = form;

    const create = useCallback(
        async (paymentMethodOrder: string[]) => {
            if (!values) {
                return;
            }

            const sortedPaymentMethods = [...values.paymentMethods].sort(
                (a, b) =>
                    paymentMethodOrder.indexOf(a.methodId) -
                    paymentMethodOrder.indexOf(b.methodId)
            );

            let paymentConfig: FetchResult<
                GQPaymentConfigurationCreateMutation,
                Record<string, any>,
                Record<string, any>
            >;
            await handleMutationError(
                async () =>
                    (paymentConfig = await paymentConfigurationCreate({
                        variables: {
                            paymentConfiguration: {
                                name: values.name,
                                paymentMethods: sortedPaymentMethods,
                            },
                        },
                    })),
                t(
                    "backoffice.payment_configuration.created",
                    "Payment configuration created"
                ),
                () => {
                    if (
                        !paymentConfig.data ||
                        !paymentConfig.data.paymentConfigurationCreate ||
                        !paymentConfig.data.paymentConfigurationCreate.id
                    ) {
                        return;
                    }

                    reset();

                    // If the screen was loaded with a referrer, then forward to the edit with the referrer set
                    if (route.params && route.params.referrer.route) {
                        navigate("PAYMENT_CONFIGURATION_EDIT", {
                            id: paymentConfig.data.paymentConfigurationCreate
                                .id,
                            referrer: {
                                route: route.params.referrer.route,
                                id: route.params.referrer?.id,
                            },
                        });
                        return;
                    }

                    // There is no referrer, just redirect to the edit screen
                    navigate("PAYMENT_CONFIGURATION_EDIT", {
                        id: paymentConfig.data.paymentConfigurationCreate.id,
                    });
                }
            );
        },
        [
            values,
            handleMutationError,
            t,
            paymentConfigurationCreate,
            reset,
            route.params,
            navigate,
        ]
    );

    if (!formData.data) {
        return <LoadingScreen style="light" />;
    }

    return (
        <PaymentConfigurationScreen>
            <Surface>
                <PaymentConfigurationForm
                    form={form}
                    paymentMethodOptions={formData.data}
                    initialSorting={formData.data.paymentMethods.data.map(
                        pm => pm.id
                    )}
                    onSubmit={create}
                    submitButton={["common.create", "Create"]}
                />
            </Surface>
        </PaymentConfigurationScreen>
    );
}

import { useApolloClient } from "@apollo/client";
import {
    Alert,
    Button,
    Card,
    CheckBox,
    DateTimePickerInput,
    Headline,
    IconButton,
    InputControl,
    InputLabel,
    Loading,
    Spacer,
    StyleFunction,
    useMe,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { add, set, sub } from "date-fns";
import {
    DashboardDocument,
    GQDashboard,
    GQDashboardQuery,
    useDepartmentsQuery,
    useSubscribeToDashboardSubscription,
    useUserRoleConfigurationQuery,
    useUserRoleConfigurationSaveMutation,
} from "graphql-sdk";
import produce from "immer";
import { camelToSnake, dateToDateTime } from "lib";
import React, { useCallback, useEffect, useState } from "react";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { useTranslation } from "locales";
import { View, ViewStyle } from "react-native";
import { Divider, Menu } from "react-native-paper";
import Select, { MultiValue } from "react-select";
import { withSize } from "react-sizeme";
import { useHandleMutationError } from "../../hooks";
import { AdminContainer } from "../container";
import { dashboardStyleFunc } from "./styles";
import { Bookkeeping } from "./widgets/bookkeeping";
import { DepartmentSalesTop10 } from "./widgets/department-sales-top10";
import { InvoiceAverageTotal } from "./widgets/invoice-average-total";
import { InvoiceTotal } from "./widgets/invoice-total";
import { ProductGroupTop10 } from "./widgets/product-group-top10";
import { ProductSalesTop10 } from "./widgets/product-sales-top10";
import { TurnoverTotal } from "./widgets/turnover-total";
import { InvoiceAmountPerHour } from "./widgets/invoice-amount-per-hour";
import { selectComponents, selectStyles } from "../../components/multi-picker";

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(
    MerchantDashboardScreen
);

// t("dashboard.names.invoice_total","Number of transactions")
// t("dashboard.names.turnover_total","Turnover")
// t("dashboard.names.invoice_average_total","Avg. transaction")
// t("dashboard.names.product_sales_top10","Product Sales - Top 10")
// t("dashboard.names.department_sales_top10","Department Sales - Top 10")
// t("dashboard.names.product_group_top10","Product Groups - Top 10")
// t("dashboard.names.bookkeeping","Bookkeeping")
// t("dashboard.names.invoice_amount_per_hour","Invoice amount per hour")
const allWidgets = [
    {
        id: "InvoiceTotal",
        component: (data: GQDashboard) => <InvoiceTotal data={data} />,
    },
    {
        id: "TurnoverTotal",
        component: (data: GQDashboard) => <TurnoverTotal data={data} />,
    },
    {
        id: "InvoiceAverageTotal",
        component: (data: GQDashboard) => <InvoiceAverageTotal data={data} />,
    },
    {
        id: "ProductSalesTop10",
        component: (data: GQDashboard) => <ProductSalesTop10 data={data} />,
    },
    {
        id: "DepartmentSalesTop10",
        component: (data: GQDashboard) => <DepartmentSalesTop10 data={data} />,
    },
    {
        id: "ProductGroupTop10",
        component: (data: GQDashboard) => <ProductGroupTop10 data={data} />,
    },
    {
        id: "Bookkeeping",
        component: (data: GQDashboard) => <Bookkeeping data={data} />,
    },
    {
        id: "InvoiceAmountPerHour",
        component: (data: GQDashboard) => <InvoiceAmountPerHour data={data} />,
    },
];

// Initial layout of the dashboard
const initialLayouts: ReactGridLayout.Layouts = {
    lg: [
        { i: "InvoiceTotal", x: 0, y: 0, w: 4, h: 6, minW: 2, minH: 5 },
        { i: "TurnoverTotal", x: 4, y: 0, w: 4, h: 6, minW: 2, minH: 6 },
        { i: "InvoiceAverageTotal", x: 8, y: 0, w: 4, h: 6, minW: 2, minH: 6 },
        { i: "ProductSalesTop10", x: 0, y: 3, w: 6, h: 13, minW: 2, minH: 7 },
        {
            i: "DepartmentSalesTop10",
            x: 6,
            y: 3,
            w: 6,
            h: 13,
            minW: 2,
            minH: 7,
        },
        { i: "ProductGroupTop10", x: 0, y: 8, w: 6, h: 13, minW: 2, minH: 7 },
        { i: "Bookkeeping", x: 6, y: 8, w: 6, h: 13, minW: 2, minH: 7 },
        {
            i: "InvoiceAmountPerHour",
            x: 0,
            y: 8,
            w: 12,
            h: 17,
            minW: 6,
            minH: 10,
        },
    ],
    md: [
        { i: "InvoiceTotal", x: 0, y: 0, w: 6, h: 6, minW: 2, minH: 5 },
        { i: "TurnoverTotal", x: 6, y: 0, w: 6, h: 6, minW: 2, minH: 6 },
        { i: "InvoiceAverageTotal", x: 12, y: 0, w: 6, h: 6, minW: 2, minH: 6 },
        { i: "ProductSalesTop10", x: 18, y: 3, w: 6, h: 13, minW: 2, minH: 7 },
        {
            i: "DepartmentSalesTop10",
            x: 6,
            y: 3,
            w: 12,
            h: 13,
            minW: 2,
            minH: 7,
        },
        { i: "ProductGroupTop10", x: 18, y: 8, w: 6, h: 13, minW: 2, minH: 7 },
        { i: "Bookkeeping", x: 18, y: 8, w: 6, h: 13, minW: 2, minH: 7 },
        {
            i: "InvoiceAmountPerHour",
            x: 0,
            y: 12,
            w: 12,
            h: 17,
            minW: 4,
            minH: 7,
        },
    ],
};

const gridMargin: [number, number] = [20, 20];
const gridBreakpoints = { lg: 1200, md: 996 };
const gridCols = { lg: 12, md: 6 };

export function MerchantDashboardScreen() {
    const graphQlClient = useApolloClient();
    const [t] = useTranslation();
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);
    const sharedStyles = useThemedStyle(dashboardStyleFunc);
    const me = useMe();
    const { handleMutationError } = useHandleMutationError();

    const [dashboardData, setDashboardData] = useState<GQDashboard>();
    const [fromDate, setFromDate] = useState<Date>(today());
    const [toDate, setToDate] = useState<Date>(nextDay());
    const [allowDateForward, setAllowDateForward] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    // Contains the widgets shown on the dashboard
    const [widgets, setWidgets] = useState(allWidgets);
    // Contains the layout of the dashboard
    const [layouts, setLayouts] =
        useState<ReactGridLayout.Layouts>(initialLayouts);
    // Used for storing the widgets from before editing. If the user cancels editing this will be used as widgets again
    const [originalWidgets, setOriginalWidgets] = useState(allWidgets);
    // Used for storing the layout from before editing. If the user cancels editing this will be used as layout again
    const [originalLayouts, setOriginalLayouts] =
        useState<ReactGridLayout.Layouts>();
    const [isEditing, setIsEditing] = useState(false);
    const [width, setWidth] = useState(0);

    const [widgetMenuVisible, setWidgetMenuVisible] = useState(false);
    const [departmentIds, setDepartmentIds] = useState<string[]>([]);
    const [configurationLoaded, setConfigurationLoaded] = useState(false);

    const {
        loading: liveLoading,
        data: liveData,
        error: liveError,
    } = useSubscribeToDashboardSubscription({
        variables: {
            from: today(),
            to: nextDay(),
            departmentIds,
        },
        shouldResubscribe: true,
        // Skip subscription if query is loading. Apollo becomes cranky if we run this at the same time
        skip: !dashboardData,
    });

    const { data: departmentsData } = useDepartmentsQuery({
        variables: {
            pagination: {
                page: 0,
                pageSize: 999999,
                sort: "name",
                sortDirection: "ASC",
            },
        },
        fetchPolicy: "network-only",
    });

    const { data: userRoleData } = useUserRoleConfigurationQuery({
        variables: {
            userId: me.user!.id,
            merchantId: me.merchant!.id,
            roleId: me.role!.id,
            type: "merchantDashboard",
        },
    });
    const [userRoleConfigurationSave] = useUserRoleConfigurationSaveMutation();

    // When configuration has been loaded, update the widgets, layout and departments
    useEffect(() => {
        if (
            !configurationLoaded &&
            userRoleData &&
            userRoleData.userRoleConfiguration
        ) {
            const json = JSON.parse(userRoleData.userRoleConfiguration.data);
            setLayouts(json.layout);
            setDepartmentIds(json.departmentIds);
            const loadedWidgets: {
                id: string;
                component: (data: GQDashboard) => JSX.Element;
            }[] = [];

            for (let i = 0; i < json.layout.lg.length; i++) {
                const widget = allWidgets.find(
                    itr => itr.id === json.layout.lg[i].i
                );
                if (widget) {
                    loadedWidgets.push(widget);
                }
            }
            if (loadedWidgets && loadedWidgets.length > 0) {
                setWidgets(loadedWidgets);
            }
            setConfigurationLoaded(true);
        }
    }, [configurationLoaded, userRoleData, widgets]);

    useEffect(() => {
        if (!liveLoading && liveData?.dashboardUpdated) {
            if (toDate < new Date()) {
                // It's the past, man. There's no need to update the dashboard.
                return;
            }

            setDashboardData(liveData.dashboardUpdated as GQDashboard);
        }
    }, [liveData, toDate, liveLoading]);

    const fetchDashboardData = useCallback(
        (from: Date, to: Date, depIds: string[]) => {
            setIsLoading(true);
            graphQlClient
                .query<GQDashboardQuery>({
                    query: DashboardDocument,
                    fetchPolicy: "no-cache",
                    variables: {
                        from,
                        to,
                        departmentIds: depIds,
                    },
                })
                .then(result => {
                    setIsLoading(false);
                    if (result.data.dashboard) {
                        setDashboardData(result.data.dashboard);
                    } else {
                        console.warn(
                            "There is no useful data in the response from GQL query Dashboard()."
                        );
                    }
                });
        },
        [graphQlClient]
    );

    useEffect(() => {
        if (!dashboardData) {
            fetchDashboardData(fromDate, toDate, departmentIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDateChange = useCallback(
        (dateObj: Date) => {
            if (!dateObj === undefined) {
                return;
            }

            const from = today(dateObj);
            const to = nextDay(dateObj);

            setFromDate(from);
            setToDate(to);

            if (to < new Date()) {
                setAllowDateForward(true);
            } else {
                setAllowDateForward(false);
            }

            fetchDashboardData(from, to, departmentIds);
        },
        [departmentIds, fetchDashboardData]
    );

    const dateBackward = useCallback(
        () => handleDateChange(previousDay(fromDate)),
        [fromDate, handleDateChange]
    );
    const dateForward = useCallback(
        () => handleDateChange(nextDay(fromDate)),
        [fromDate, handleDateChange]
    );

    const handleEditing = useCallback(() => {
        setOriginalWidgets(widgets);
        setOriginalLayouts(layouts);
        setIsEditing(!isEditing);
    }, [isEditing, layouts, widgets]);

    const handleDepartmentChange = useCallback(
        (
            newValue: MultiValue<{
                value: string;
                label: string;
            }>
        ) => {
            setDepartmentIds(newValue.map(itr => itr.value));
        },
        []
    );

    const handleLayoutChange = (
        _currentLayout: ReactGridLayout.Layout[],
        allLayouts: ReactGridLayout.Layouts
    ) => {
        setLayouts(allLayouts);
    };

    const handleLayoutSave = useCallback(async () => {
        setIsEditing(false);
        await handleMutationError(
            async () =>
                await userRoleConfigurationSave({
                    variables: {
                        userId: me.user!.id,
                        merchantId: me.merchant!.id,
                        roleId: me.role!.id,
                        type: "merchantDashboard",
                        configuration: JSON.stringify({
                            departmentIds: departmentIds,
                            layout: layouts,
                        }),
                    },
                }),
            t("common.saved", "Saved")
        );
    }, [
        departmentIds,
        handleMutationError,
        layouts,
        me.merchant,
        me.role,
        me.user,
        t,
        userRoleConfigurationSave,
    ]);

    const handleLayoutCancel = () => {
        setIsEditing(false);
        setWidgets(originalWidgets);
        if (originalLayouts) {
            setLayouts(originalLayouts);
        }
    };

    const handleRemoveWidget = (itemId: string) => {
        setWidgets(widgets.filter(i => i.id !== itemId));
    };

    const handleAddWidget = (itemId: string) => {
        setWidgets([...widgets, allWidgets.find(itr => itr.id === itemId)!]);
        const addLayoutLg = initialLayouts.lg.find(itr => itr.i === itemId);
        const addLayoutMd = initialLayouts.md.find(itr => itr.i === itemId);
        setLayouts(
            produce(layouts, draft => {
                draft.lg.push(addLayoutLg!);
                draft.md.push(addLayoutMd!);
            })
        );
    };

    if (liveError) {
        return <Alert type="error">{`Error: ${liveError.message}`}</Alert>;
    }

    if (!dashboardData) {
        return (
            <AdminContainer>
                <Loading message={t("common.loading", "Loading")} />
            </AdminContainer>
        );
    }

    return (
        <AdminContainer>
            <View
                style={sharedStyles.topRow}
                testID="merchantDashboard"
                onLayout={event => {
                    setWidth(event.nativeEvent.layout.width);
                }}
            >
                <View style={theme.styles.row}>
                    <DateTimePickerInput
                        dateTimeType="date"
                        label={t("common.date", "Date")}
                        onChangeDate={handleDateChange}
                        value={dateToDateTime(fromDate)}
                        maxDate={today()}
                    />
                    <IconButton
                        name="caret-left"
                        onPress={dateBackward}
                        color={theme.colors.secondary}
                        style={sharedStyles.calendarArrow}
                    />
                    <IconButton
                        name="caret-right"
                        onPress={dateForward}
                        color={theme.colors.secondary}
                        disabled={!allowDateForward}
                        style={sharedStyles.calendarArrow}
                    />
                </View>

                <View>
                    <Button onPress={handleEditing} disabled={isEditing}>
                        {t("common.edit", "Edit")}
                    </Button>
                </View>
            </View>
            {isEditing && (
                <Card style={styles.topBar}>
                    <Card.Content>
                        <View style={theme.styles.row}>
                            <InputControl
                                description={t(
                                    "dashboard.widgets.department_description",
                                    "Select department(s) to show. If no department is selected all departments is shown"
                                )}
                            >
                                <InputLabel>
                                    {t("common.department", "Department")}
                                </InputLabel>
                                <Select
                                    isMulti={true}
                                    hideSelectedOptions={false}
                                    options={
                                        // Map the departments to the format for the select
                                        departmentsData?.departments.data.map(
                                            item => {
                                                return {
                                                    value: item.id,
                                                    label: item.name,
                                                };
                                            }
                                        )
                                    }
                                    defaultValue={
                                        // Filter for only the selected departments from configuration
                                        departmentsData?.departments.data
                                            .filter(itr =>
                                                departmentIds.includes(itr.id)
                                            )
                                            // Map the departments to the format for the select
                                            .map(item => {
                                                return {
                                                    value: item.id,
                                                    label: item.name,
                                                };
                                            }) || []
                                    }
                                    onChange={handleDepartmentChange}
                                    styles={selectStyles}
                                    components={selectComponents}
                                />
                            </InputControl>
                            <Spacer space={2} />
                            <Menu
                                visible={widgetMenuVisible}
                                onDismiss={() => setWidgetMenuVisible(false)}
                                anchor={
                                    <>
                                        <Spacer space={2} />
                                        <Button
                                            style={styles.button}
                                            onPress={() =>
                                                setWidgetMenuVisible(true)
                                            }
                                        >
                                            {t(
                                                "dashboard.widgets.header",
                                                "Widgets"
                                            )}
                                        </Button>
                                    </>
                                }
                            >
                                <View style={styles.widgetOptionContainer}>
                                    <Spacer />
                                    <Headline
                                        size="h5"
                                        style={styles.widgetHeader}
                                    >
                                        {t(
                                            "dashboard.widgets.header",
                                            "Widgets"
                                        )}
                                    </Headline>

                                    <Divider style={styles.widgetDivider} />
                                    <Spacer />
                                    {allWidgets.map(({ id }, index) => {
                                        return (
                                            <CheckBox
                                                key={`checkbox:${id}`}
                                                style={[
                                                    index % 2
                                                        ? theme.styles.oddRow
                                                        : undefined,
                                                ]}
                                                label={t(
                                                    "dashboard.names." +
                                                        camelToSnake(id),
                                                    id
                                                )}
                                                value={
                                                    widgets.find(
                                                        itr => itr.id === id
                                                    ) !== undefined
                                                }
                                                onValueChange={value => {
                                                    if (value) {
                                                        handleAddWidget(id);
                                                    } else {
                                                        handleRemoveWidget(id);
                                                    }
                                                }}
                                            />
                                        );
                                    })}
                                </View>
                            </Menu>
                        </View>
                    </Card.Content>

                    <Card.Actions>
                        <Button
                            style={styles.button}
                            onPress={handleLayoutSave}
                        >
                            {t("common.save", "Save")}
                        </Button>
                    </Card.Actions>
                    <IconButton
                        name="close"
                        style={styles.closeIcon}
                        onPress={handleLayoutCancel}
                    />
                </Card>
            )}
            {isLoading ? (
                <Loading />
            ) : (
                <ResponsiveGridLayout
                    className="layout"
                    layouts={layouts}
                    rowHeight={10}
                    width={width}
                    autoSize={true}
                    onLayoutChange={handleLayoutChange}
                    margin={gridMargin}
                    isDraggable={isEditing}
                    isResizable={isEditing}
                    isDroppable={isEditing}
                    breakpoints={gridBreakpoints}
                    cols={gridCols}
                >
                    {widgets.map(({ id, component }) => (
                        <div key={id} className="widget">
                            <Card style={sharedStyles.widgetContent}>
                                {component(dashboardData)}
                            </Card>
                            {isEditing && (
                                <div className="gridHandle">
                                    <IconButton
                                        onPress={() => {
                                            handleRemoveWidget(id);
                                        }}
                                        name="close"
                                        style={styles.closeIcon}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </ResponsiveGridLayout>
            )}
        </AdminContainer>
    );
}

const previousDay = (date = new Date()) =>
    set(sub(date, { days: 1 }), {
        hours: 6,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });

function today(date = new Date()) {
    return set(date, {
        hours: 6,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });
}

function nextDay(date = new Date()) {
    return set(add(date, { days: 1 }), {
        hours: 6,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });
}

const styleFunc: StyleFunction = theme => ({
    closeIcon: {
        position: "absolute",
        color: theme.colors.primary,
        top: 0,
        right: 0,
        elevation: 1,
    },
    button: {
        minWidth: 150,
    },
    widgetOptionContainer: {
        marginHorizontal: theme.spacingScale * 2,
    },
    widgetHeader: {
        ...(theme.headlines.h5 as ViewStyle),
        marginBottom: theme.spacingScale,
    },
    widgetDivider: {
        marginHorizontal: -(theme.spacingScale * 2),
        backgroundColor: theme.colors.grey100,
    },
    topBar: {
        marginHorizontal: theme.spacingScale * 2,
        zIndex: 100,
    },
});

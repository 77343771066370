import { View } from "react-native";
import {
    Headline,
    IconButton,
    NumpadConfirmButton,
    PinPad,
    StyleFunction,
    useThemedStyle,
} from "../../";
import React, { useState } from "react";
import { useTranslation } from "locales";

export function Pin({
    alertMessage,
    onSubmit,
    onClear,
    headerText,
}: {
    onSubmit: (pinValue: string) => void;
    onClear: () => void;
    alertMessage?: string;
    headerText: string;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [numPadValue, setNumPadValue] = useState<string>("");

    const disabledButton = numPadValue.length === 0;

    return (
        <View>
            <View style={styles.header}>
                <Headline size="h2" style={styles.headlineText}>
                    {headerText}
                </Headline>
                <View style={styles.icon}>
                    <IconButton
                        size="large"
                        color={styles.icon.color}
                        name="times-circle"
                        onPress={onClear}
                    />
                </View>
            </View>
            <View style={styles.container}>
                <PinPad
                    value=""
                    onValueChange={setNumPadValue}
                    maxLength={10}
                    showDisplay={true}
                    maskInput={true}
                    alertMessage={alertMessage}
                />
                <NumpadConfirmButton
                    onPress={() => onSubmit(numPadValue)}
                    text={t("common.numpad.ok", "OK")}
                    buttonStyle={[
                        styles.confirmButton,
                        disabledButton ? styles.disabledButton : null,
                    ]}
                    textStyle={[
                        styles.confirmButtonText,
                        disabledButton ? styles.disabledButtonText : null,
                    ]}
                    disabled={disabledButton}
                    testID="pin:ok"
                />
            </View>
        </View>
    );
}

const styleFunc: StyleFunction = (theme, dimensions) => ({
    container: {
        width: Math.min(dimensions.width - theme.spacingScale * 2, 500),
        height: dimensions.height - theme.spacingScale * 7,
        alignSelf: "center",
        justifyContent: "center",
    },
    confirmButton: {
        backgroundColor: theme.colors.secondary,
    },
    confirmButtonText: {
        color: theme.colors.textLight,
        fontWeight: "bold",
    },
    icon: {
        elevation: 20,
        color: theme.colors.white,
    },
    header: {
        flexDirection: "row",
        alignItems: "stretch",
        justifyContent: "space-between",
        paddingRight: theme.spacingScale,
        paddingTop: theme.spacingScale,
    },
    headlineText: {
        flex: 1,
        color: theme.colors.textLight,
        textAlign: "center",
        marginLeft: theme.spacingScale,
    },
    disabledButton: {
        backgroundColor: theme.colors.grey100,
    },
    disabledButtonText: {
        color: theme.colors.grey250,
    },
});

import React, { useEffect, useState } from "react";
import { useThemedStyle, StyleFunction } from "../../theme";
import { View, ViewStyle, StyleProp, Pressable } from "react-native";
import { Icon } from "../icon";
import { Text } from "../text";

export function CheckBox({
    label,
    value: defaultValue,
    onValueChange,
    style,
    disabled,
    testID,
}: {
    label?: string;
    value?: boolean;
    onValueChange?: (value: boolean) => void;
    style?: StyleProp<ViewStyle>;
    disabled?: boolean;
    testID?: string;
}) {
    const [value, setValue] = useState<boolean>(defaultValue || false);

    const styles = useThemedStyle(styleFunc);

    useEffect(() => {
        if (defaultValue !== undefined && defaultValue !== value) {
            setValue(defaultValue);
        }
    }, [defaultValue, value]);

    return (
        <View
            style={[styles.container, style]}
            testID={`${testID}:${value ? "isChecked" : "isUnchecked"}`}
        >
            {!disabled ? (
                <Pressable
                    style={styles.checkContainer}
                    onPress={() => {
                        setValue(!value);
                        if (onValueChange) {
                            onValueChange(!value);
                        }
                    }}
                    testID={testID}
                >
                    <View
                        style={[
                            styles.checkBox,
                            value ? styles.checked : styles.unchecked,
                        ]}
                    >
                        {value && (
                            <Icon name="check" color={styles.icon.color} />
                        )}
                    </View>
                    {label && (
                        <Text
                            style={[
                                styles.label,
                                value
                                    ? styles.checkedLabel
                                    : styles.uncheckedLabel,
                            ]}
                        >
                            {label}
                        </Text>
                    )}
                </Pressable>
            ) : (
                <View style={styles.checkContainer}>
                    <View
                        style={[styles.checkBox, styles.disabled]}
                        testID={testID}
                    >
                        {value && (
                            <Icon
                                name="check"
                                color={styles.disabledIcon.color}
                            />
                        )}
                    </View>
                    {label && (
                        <Text style={[styles.label, styles.disabledLabel]}>
                            {label}
                        </Text>
                    )}
                </View>
            )}
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flex: 1,
        flexDirection: "column",
        marginBottom: theme.spacingScale,
    },

    checkContainer: {
        flex: 1,
        flexDirection: "row",
    },

    checkBox: {
        height: 24,
        width: 24,
        borderWidth: 1,
        alignItems: "center",
        justifyContent: "center",
    },

    checked: {
        borderColor: theme.colors.secondary,
        backgroundColor: theme.colors.secondary,
    },

    unchecked: {
        borderColor: theme.colors.grey500,
        backgroundColor: theme.colors.white,
    },

    icon: {
        color: theme.colors.white,
    },

    label: {
        ...theme.fonts.regular,
        marginLeft: theme.spacingScale * 2,
        fontWeight: "bold",
    },

    checkedLabel: {
        color: theme.colors.textDark,
    },

    uncheckedLabel: {
        color: theme.colors.textDark,
    },

    disabled: {
        backgroundColor: theme.colors.grey100,
        borderColor: theme.colors.grey250,
    },

    disabledLabel: {
        color: theme.colors.grey500,
    },

    disabledIcon: {
        color: theme.colors.grey500,
    },
});

import React from "react";
import { useTranslation } from "locales";
import {
    DataTable,
    Icon,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import type { SortDirection, StyleFunction } from "@venuepos/react-common";

export function TransactionTableHeader({
    onSortChange,
    sortBy,
    sortDirection,
}: {
    onSortChange: (field: string) => void;
    sortBy: string | undefined;
    sortDirection: SortDirection;
}) {
    const styles = useThemedStyle(styleFunc);

    const [t] = useTranslation();
    const theme = useTheme();

    return (
        <DataTable.Header>
            <DataTable.Title
                sortDirection={
                    sortBy === "transactionAt" ? sortDirection : undefined
                }
                onPress={() => onSortChange("transactionAt")}
            >
                {t("common.created_at", "Created at")}
            </DataTable.Title>
            <DataTable.Title
                sortDirection={
                    sortBy === "username" ? sortDirection : undefined
                }
                onPress={() => onSortChange("username")}
            >
                {t("common.user", "User")}
            </DataTable.Title>
            <DataTable.Title
                sortDirection={
                    sortBy === "tagIdentifier" ? sortDirection : undefined
                }
                onPress={() => onSortChange("tagIdentifier")}
            >
                {t("common.tag", "Tag")}
            </DataTable.Title>
            <DataTable.Title
                numeric
                sortDirection={sortBy === "amount" ? sortDirection : undefined}
                onPress={() => onSortChange("amount")}
            >
                {t("common.amount", "Amount")}
            </DataTable.Title>
            <DataTable.Title numeric style={styles.iconColumn}>
                <Icon name="sort" color={theme.colors.black} />
            </DataTable.Title>
        </DataTable.Header>
    );
}

const styleFunc: StyleFunction = () => ({
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 100,
    },
});

import { ApolloError } from "@apollo/client";
import {
    Alert,
    Headline,
    Loading,
    PrinterWrapper,
    Spacer,
    StyleFunction,
    Surface,
    Text,
    useAuth,
    useMerchantConfig,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQInvoiceHourSales, GQShiftQuery, useShiftQuery } from "graphql-sdk";
import { AvailableLocale, useTranslation } from "locales";
import React, { View } from "react-native";

import { formatAmount, formatDateTime } from "lib";
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryTheme,
    VictoryTooltip,
} from "victory";
import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";

type ScreenProps = RootStackScreenProps<"SHIFT">;

export function ShiftScreen({ route }: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.shift");

    const [{ locale }] = useAdminSession(["locale"]);

    const shiftId = route.params.id;
    const { data, loading, error } = useShiftQuery({
        variables: {
            id: shiftId,
        },
        fetchPolicy: "no-cache",
    });

    return (
        <AdminContainer>
            <Shift
                loading={loading}
                error={error}
                data={data}
                locale={locale as AvailableLocale}
            />
        </AdminContainer>
    );
}

const BAR_CHART_PADDING = {
    top: 10,
    bottom: 40,
    left: 100,
    right: 100,
};

function Shift({
    loading,
    error,
    data,
    locale,
}: {
    loading: boolean;
    error?: ApolloError;
    data?: GQShiftQuery;
    locale: AvailableLocale;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const { currency } = useMerchantConfig();
    const theme = useTheme();

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error.message,
                    }
                )}
            </Alert>
        );
    }

    if (!data?.shift) {
        return (
            <Alert type="info">
                {t("backoffice.shift.no_data", "There is no data to show.")}
            </Alert>
        );
    }

    const cashAtOpen =
        data.shift.payments?.find(
            itr => itr.type === "CASH" && itr.event === "OPEN"
        )?.amount || 0;

    const paymentTypeData =
        data.shift.payments?.filter(itr => itr.event === "CLOSE") || [];

    const cardPaymentSchemeData = data.shift.payments?.filter(
        itr => itr.type === "CARD" && itr.cardSchemas !== undefined
    );
    const cardSchemeData = cardPaymentSchemeData?.reduce(
        (acc, cur) => acc.concat(acc, cur.cardSchemas!),
        [] as { name: string; amount: number; count: number }[]
    );

    const getBarChartHeight = (numberOfItems: number = 1) => {
        const rowHeight = 15;
        const minHeight = 50;
        return numberOfItems * rowHeight + minHeight;
    };

    const getStackedBarWidth = (
        width: number,
        datasets: GQInvoiceHourSales[]
    ) => {
        const setLength = datasets.length;
        const chartWidth =
            width - BAR_CHART_PADDING.left - BAR_CHART_PADDING.right;
        // Set the width of the bars so that they take up about 60% of the width
        return (chartWidth * 0.6) / setLength;
    };

    return (
        <View>
            <Surface style={styles.elementSpacing}>
                <View style={styles.header}>
                    <View style={styles.headerItem}>
                        <Headline size="h5" style={styles.headline}>
                            {t("backoffice.shifts.opened_at", "Opened")}
                        </Headline>
                        <Text>
                            {t(
                                "backoffice.shift.opened_by_username",
                                "{{date}} by {{username}}",
                                {
                                    date: formatDateTime(
                                        data.shift.openedAt,
                                        locale
                                    ),
                                    username: data.shift.openedByUser?.username,
                                    interpolation: {
                                        escapeValue: false,
                                    },
                                }
                            )}
                        </Text>
                    </View>
                    <View style={styles.headerItem}>
                        <Headline size="h5" style={styles.headline}>
                            {t("backoffice.shifts.closed_at", "Closed")}
                        </Headline>
                        <Text>
                            {data.shift.closedAt
                                ? t(
                                      "backoffice.shift.closed_by_username",
                                      "{{date}} by {{username}}",
                                      {
                                          date: formatDateTime(
                                              data.shift.closedAt,
                                              locale
                                          ),
                                          username:
                                              data.shift.closedByUser?.username,
                                          interpolation: {
                                              escapeValue: false,
                                          },
                                      }
                                  )
                                : "-"}
                        </Text>
                    </View>
                    <View style={styles.headerItem}>
                        <Headline size="h5" style={styles.headline}>
                            {t(
                                "backoffice.shifts.cash_register_name",
                                "Cash register"
                            )}
                        </Headline>
                        <Text>{data.shift.cashRegister.name}</Text>
                    </View>
                </View>
            </Surface>
            <Surface style={styles.elementSpacing}>
                <View style={styles.content}>
                    <View style={styles.contentItem}>
                        <Headline size="h5" style={styles.headline}>
                            {t("backoffice.shift.total_sales", "Total sales")}
                        </Headline>
                        <View style={styles.items}>
                            <Text style={styles.itemLeft}>
                                {t("common.total", "Total")}
                            </Text>
                            <Text style={styles.itemRight}>
                                {formatAmount(data.shift.amount, currency, {
                                    printCurrency: true,
                                })}
                            </Text>
                        </View>
                        <View style={styles.items}>
                            <Text style={styles.itemLeft}>
                                {t("common.discount.", "Discount")}
                            </Text>
                            <Text style={styles.itemRight}>
                                {formatAmount(data.shift.discount, currency, {
                                    printCurrency: true,
                                })}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.contentItem}>
                        <Headline size="h5" style={styles.headline}>
                            {t("common.balance.", "Balance")}
                        </Headline>
                        <View style={styles.items}>
                            <Text style={styles.itemLeft}>
                                {t(
                                    "backoffice.shift.cash_at_open",
                                    "Cash at open"
                                )}
                            </Text>
                            <Text style={styles.itemRight}>
                                {formatAmount(cashAtOpen, currency, {
                                    printCurrency: true,
                                })}
                            </Text>
                        </View>
                        {data.shift.funds?.map((item, i) => (
                            <View style={styles.items} key={`funds_${i}`}>
                                <Text style={styles.itemLeft}>
                                    {item.type === "IN"
                                        ? t(
                                              "backoffice.shift.cash_in",
                                              "Cash in ({{amount}})",
                                              {
                                                  amount: formatDateTime(
                                                      item.createdAt,
                                                      locale
                                                  ),
                                                  interpolation: {
                                                      escapeValue: false,
                                                  },
                                              }
                                          )
                                        : t(
                                              "backoffice.shift.cash_out",
                                              "Cash out ({{amount}})",
                                              {
                                                  amount: formatDateTime(
                                                      item.createdAt,
                                                      locale
                                                  ),
                                                  interpolation: {
                                                      escapeValue: false,
                                                  },
                                              }
                                          )}
                                </Text>
                                <Text style={styles.itemRight}>
                                    {formatAmount(
                                        item.amount *
                                            (item.type === "IN" ? 1 : -1),
                                        currency,
                                        {
                                            printCurrency: true,
                                        }
                                    )}
                                </Text>
                            </View>
                        ))}
                        <View style={styles.items}>
                            <Text style={styles.itemLeft}>
                                {t(
                                    "backoffice.shift.cash_at_close",
                                    "Cash at close"
                                )}
                            </Text>
                            <Text style={styles.itemRight}>
                                {data.shift.closedAt
                                    ? formatAmount(
                                          data.shift.payments?.find(
                                              itr =>
                                                  itr.type === "CASH" &&
                                                  itr.event === "CLOSE"
                                          )?.amount || 0,
                                          currency,
                                          {
                                              printCurrency: true,
                                          }
                                      )
                                    : "-"}
                            </Text>
                        </View>
                        <View style={styles.items}>
                            <Text style={styles.itemLeft}>
                                {t("backoffice.shift.difference", "Difference")}
                            </Text>
                            <Text style={styles.itemRight}>
                                {data.shift.closedAt
                                    ? formatAmount(
                                          data.shift.cashDifference,
                                          currency,
                                          {
                                              printCurrency: true,
                                          }
                                      )
                                    : "-"}
                            </Text>
                        </View>
                    </View>
                </View>
            </Surface>
            <Surface style={styles.elementSpacing}>
                <Headline size="h4" style={styles.headline}>
                    {t(
                        "backoffice.shift.sales_by_productgroup",
                        "Sales by productgroup"
                    )}
                </Headline>
                <View style={styles.content}>
                    <View style={styles.contentItem}>
                        <View style={styles.items}>
                            <Text style={[styles.tableHeader, styles.itemLeft]}>
                                {t("common.product_group", "Product group")}
                            </Text>
                            <Text
                                style={[styles.tableHeader, styles.itemRight]}
                            >
                                {t("common.amount", "Amount")}
                            </Text>
                        </View>
                        {data.shift.salesData?.byProductGroup?.map(
                            (item, i) => {
                                return (
                                    <View
                                        style={styles.items}
                                        key={`product_group_${i}`}
                                    >
                                        <Text style={styles.itemLeft}>
                                            {item.name}
                                        </Text>
                                        <Text style={styles.itemRight}>
                                            {formatAmount(
                                                item.amount,
                                                currency,
                                                {
                                                    printCurrency: true,
                                                }
                                            )}
                                        </Text>
                                    </View>
                                );
                            }
                        )}
                    </View>
                    <View style={styles.contentItem}>
                        <VictoryChart
                            theme={VictoryTheme.material}
                            height={getBarChartHeight(
                                data.shift.salesData?.byProductGroup?.length
                            )}
                            width={500}
                            padding={BAR_CHART_PADDING}
                            domainPadding={{
                                x: 10,
                            }}
                        >
                            <VictoryAxis
                                dependentAxis
                                crossAxis={false} // Force showing of 0
                                tickCount={4}
                                fixLabelOverlap
                                tickFormat={tick => {
                                    return formatAmount(tick, currency);
                                }}
                            />
                            <VictoryAxis
                                tickFormat={_tick => {
                                    // No ticks
                                    return "";
                                }}
                            />
                            <VictoryBar
                                horizontal
                                data={data.shift.salesData?.byProductGroup?.reverse()}
                                labels={({ datum }) => datum.name}
                                y="amount"
                                x="name"
                                style={{
                                    data: {
                                        fill: ({ datum }) =>
                                            datum.amount > 0
                                                ? theme.colors.secondary
                                                : theme.colors.error,
                                    },
                                    labels: {
                                        fontSize: 8,
                                    },
                                }}
                            />
                        </VictoryChart>
                    </View>
                </View>
            </Surface>
            <Surface style={styles.elementSpacing}>
                <Headline size="h4" style={styles.headline}>
                    {t(
                        "backoffice.shift.sales_by_payment_type",
                        "Sales by payment type"
                    )}
                </Headline>
                {paymentTypeData.length === 0 ? (
                    <Text>
                        {t(
                            "backoffice.shift.no_payment_types_to_show",
                            "No payments methods to show"
                        )}
                    </Text>
                ) : (
                    <View style={styles.content}>
                        <View style={styles.contentItem}>
                            <View style={styles.items}>
                                <Text
                                    style={[
                                        styles.tableHeader,
                                        styles.itemLeft,
                                    ]}
                                >
                                    {t(
                                        "common.payment_method",
                                        "Payment method"
                                    )}
                                </Text>
                                <Text
                                    style={[
                                        styles.tableHeader,
                                        styles.itemRight,
                                    ]}
                                >
                                    {t("common.amount", "Amount")}
                                </Text>
                            </View>
                            {paymentTypeData.map((item, i) => {
                                return (
                                    <View
                                        style={styles.items}
                                        key={`product_group_${i}`}
                                    >
                                        <Text style={styles.itemLeft}>
                                            {item.name}
                                        </Text>
                                        <Text style={styles.itemRight}>
                                            {formatAmount(
                                                item.amount,
                                                currency,
                                                {
                                                    printCurrency: true,
                                                }
                                            )}
                                        </Text>
                                    </View>
                                );
                            })}
                        </View>
                        <View style={styles.contentItem}>
                            <VictoryChart
                                horizontal
                                theme={VictoryTheme.material}
                                height={getBarChartHeight(
                                    paymentTypeData.length
                                )}
                                width={500}
                                padding={BAR_CHART_PADDING}
                                domainPadding={{ x: 10 }}
                            >
                                <VictoryAxis
                                    dependentAxis
                                    crossAxis={false} // Force showing of 0
                                    tickCount={2}
                                    tickFormat={tick => {
                                        return formatAmount(tick, currency);
                                    }}
                                />
                                <VictoryAxis
                                    tickFormat={_tick => {
                                        // No ticks
                                        return "";
                                    }}
                                />
                                <VictoryBar
                                    data={paymentTypeData.reverse()}
                                    labels={({ datum }) => datum.name}
                                    y="amount"
                                    x="name"
                                    style={{
                                        data: {
                                            fill: ({ datum }) =>
                                                datum.amount > 0
                                                    ? theme.colors.secondary
                                                    : theme.colors.error,
                                        },
                                        labels: {
                                            fontSize: 8,
                                        },
                                    }}
                                />
                            </VictoryChart>
                        </View>
                    </View>
                )}
            </Surface>

            <Surface style={styles.elementSpacing}>
                <Headline size="h4" style={styles.headline}>
                    {t(
                        "backoffice.shift.sales_by_card_scheme",
                        "Sales by card scheme"
                    )}
                </Headline>
                {cardSchemeData?.length === 0 ? (
                    <Text>
                        {t(
                            "backoffice.shift.no_card_schemes_to_show",
                            "No card schemes to show"
                        )}
                    </Text>
                ) : (
                    <View style={styles.content}>
                        <View style={styles.contentItem}>
                            <View style={styles.items}>
                                <Text
                                    style={[
                                        styles.tableHeader,
                                        styles.itemLeft,
                                    ]}
                                >
                                    {t(
                                        "backoffice.shift.card_scheme",
                                        "Card scheme"
                                    )}
                                </Text>
                                <Text
                                    style={[
                                        styles.tableHeader,
                                        styles.itemCenter,
                                    ]}
                                >
                                    {t("common.count", "Count")}
                                </Text>
                                <Text
                                    style={[
                                        styles.tableHeader,
                                        styles.itemRight,
                                    ]}
                                >
                                    {t("common.amount", "Amount")}
                                </Text>
                            </View>
                            {cardSchemeData?.map((item, i) => {
                                return (
                                    <View
                                        style={styles.items}
                                        key={`card_scheme_${i}`}
                                    >
                                        <Text style={styles.itemLeft}>
                                            {item.name}
                                        </Text>
                                        <Text style={styles.itemCenter}>
                                            {item.count}
                                        </Text>
                                        <Text style={styles.itemRight}>
                                            {formatAmount(
                                                item.amount,
                                                currency,
                                                {
                                                    printCurrency: true,
                                                }
                                            )}
                                        </Text>
                                    </View>
                                );
                            })}
                        </View>
                        <View style={styles.contentItem}>
                            <VictoryChart
                                horizontal
                                theme={VictoryTheme.material}
                                height={getBarChartHeight(
                                    cardSchemeData?.length || 0
                                )}
                                width={500}
                                padding={BAR_CHART_PADDING}
                                domainPadding={{ x: 10 }}
                            >
                                <VictoryAxis
                                    dependentAxis
                                    crossAxis={false} // Force showing of 0
                                    tickCount={2}
                                    tickFormat={tick => {
                                        return formatAmount(tick, currency);
                                    }}
                                />
                                <VictoryAxis
                                    tickFormat={_tick => {
                                        // No ticks
                                        return "";
                                    }}
                                />
                                <VictoryBar
                                    data={cardSchemeData}
                                    labels={({ datum }) => datum.name}
                                    y="amount"
                                    x="name"
                                    style={{
                                        data: {
                                            fill: ({ datum }) =>
                                                datum.amount > 0
                                                    ? theme.colors.secondary
                                                    : theme.colors.error,
                                        },
                                        labels: {
                                            fontSize: 8,
                                        },
                                    }}
                                />
                            </VictoryChart>
                        </View>
                    </View>
                )}
            </Surface>

            <Surface style={styles.elementSpacing}>
                <Headline size="h4" style={styles.headline}>
                    {t("backoffice.shift.hour_sales", "Sales by hour")}
                </Headline>
                <View style={styles.content}>
                    <View style={styles.contentItem}>
                        <View style={styles.items}>
                            <Text style={[styles.tableHeader, styles.itemLeft]}>
                                {t("common.hour", "Hour")}
                            </Text>
                            <Text
                                style={[styles.tableHeader, styles.itemRight]}
                            >
                                {t("common.count", "Count")}
                            </Text>
                            <Text
                                style={[styles.tableHeader, styles.itemRight]}
                            >
                                {t("common.amount", "Amount")}
                            </Text>
                        </View>
                        {data.shift.salesData?.hourSales?.map((item, i) => {
                            return (
                                <View
                                    style={styles.items}
                                    key={`hour_sales_${i}`}
                                >
                                    <Text style={styles.itemLeft}>
                                        {`${(
                                            "0" + new Date(item.date).getHours()
                                        ).slice(-2)}:00-${(
                                            "0" + new Date(item.date).getHours()
                                        ).slice(-2)}:59`}
                                    </Text>
                                    <Text style={styles.itemRight}>
                                        {item.count}
                                    </Text>
                                    <Text style={styles.itemRight}>
                                        {formatAmount(item.amount, currency, {
                                            printCurrency: true,
                                        })}
                                    </Text>
                                </View>
                            );
                        })}
                    </View>
                    <View style={styles.contentItem}>
                        <VictoryChart
                            theme={VictoryTheme.material}
                            height={250}
                            width={500}
                            padding={{
                                top: 10,
                                bottom: 40,
                                left: 100,
                                right: 10,
                            }}
                            domainPadding={{
                                x:
                                    getStackedBarWidth(
                                        500,
                                        data.shift.salesData?.hourSales || []
                                    ) / 2,
                            }}
                        >
                            <VictoryAxis
                                dependentAxis
                                crossAxis={false} // Force showing of 0
                            />
                            <VictoryAxis
                                tickFormat={tick => {
                                    const date = new Date(tick);
                                    return `${("0" + date.getHours()).slice(
                                        -2
                                    )}:00-${("0" + date.getHours()).slice(
                                        -2
                                    )}:59`;
                                }}
                                style={{
                                    tickLabels: {
                                        angle: 45,
                                        padding: 12,
                                        fontSize: 8,
                                        transform: "translate(15, 3)",
                                    },
                                    axisLabel: {
                                        padding: 50,
                                    },
                                }}
                            />
                            <VictoryBar
                                data={data.shift.salesData?.hourSales || []}
                                labels={({ datum }) =>
                                    formatAmount(datum.amount, currency, {
                                        printCurrency: true,
                                    })
                                }
                                labelComponent={<VictoryTooltip />}
                                y={d => d.amount / 100}
                                x="date"
                                style={{
                                    data: {
                                        fill: ({ datum }) =>
                                            datum.amount > 0
                                                ? theme.colors.secondary
                                                : theme.colors.error,
                                    },
                                    labels: {
                                        fontSize: 8,
                                    },
                                }}
                            />
                        </VictoryChart>
                    </View>
                </View>
            </Surface>
            <Surface>
                <Headline size="h4" style={styles.headline}>
                    {t("backoffice.shift.z_report", "Z report")}
                </Headline>

                {data.shift.zReport ? (
                    <PrinterWrapper>
                        <Surface style={styles.reportWrapper}>
                            <Text style={styles.receipt}>
                                {data.shift.zReport}
                            </Text>
                        </Surface>
                    </PrinterWrapper>
                ) : (
                    <>
                        <Spacer />
                        <View>
                            <Alert type="info">
                                {t(
                                    "backoffice.shift.not_closed_yet",
                                    "There is no Z report yet, since the shift is not closed."
                                )}
                            </Alert>
                        </View>
                    </>
                )}
            </Surface>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    backButton: {
        width: theme.spacingScale * 10,
        marginBottom: theme.spacingScale,
    },
    elementSpacing: { marginBottom: theme.spacingScale * 2 },
    header: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    content: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    contentItem: { flex: 1, paddingHorizontal: theme.spacingScale },
    headerItem: { flex: 1 },
    tableHeader: {
        fontFamily: "Montserrat-Bold",
        fontSize: 18,
        lineHeight: 32,
    },
    items: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    itemLeft: { flex: 1 },
    itemCenter: { flex: 1, textAlign: "center" },
    itemRight: { flex: 1, textAlign: "right" },
    headline: {
        marginBottom: 0,
    },
    receipt: {
        ...theme.fonts.monospace,
        fontSize: 14,
    },

    reportWrapper: {
        padding: theme.spacingScale * 2,
        borderWidth: 1,
        borderColor: theme.colors.grey250,
        borderRadius: 0,

        maxWidth: 400,
        elevation: 3,
    },
});

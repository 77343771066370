import { useFocusEffect } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import {
    LoadingScreen,
    StyleFunction,
    Surface,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    usePaymentConfigurationFormLazyQuery,
    usePaymentConfigurationLazyQuery,
    usePaymentConfigurationSaveMutation,
} from "graphql-sdk";
import { IPaymentConfigurationInput, schemaPaymentConfiguration } from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";
import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { RootStackParamList } from "../../navigation";
import { PaymentConfigurationForm } from "./payment-configuration-form";
import { PaymentConfigurationScreen } from "./payment-configuration-screen";

type ScreenProps = StackScreenProps<
    RootStackParamList,
    "PAYMENT_CONFIGURATION_EDIT"
>;

export function PaymentConfigurationEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.payment_configuration.write");

    const configId = route.params.id;

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [paymentConfigurationEdit] = usePaymentConfigurationSaveMutation();
    const { handleMutationError } = useHandleMutationError();

    const [getFormData, formData] = usePaymentConfigurationFormLazyQuery({
        fetchPolicy: "no-cache",
    });
    const [getConfig, config] = usePaymentConfigurationLazyQuery({
        variables: { id: configId },
        fetchPolicy: "no-cache",
    });
    const form = useForm<IPaymentConfigurationInput>(
        schemaPaymentConfiguration,
        null
    );
    const [{ values }, { setDefaultValues }] = form;

    // Fetch the data for the screen when the screen is "active"/has focus.
    useFocusEffect(
        useCallback(() => {
            getFormData();
            getConfig();
        }, [getConfig, getFormData])
    );

    useEffect(() => {
        if (!config?.data?.paymentConfiguration) {
            return;
        }

        const pc = config.data.paymentConfiguration;
        setDefaultValues({
            name: pc.name,
            paymentMethods: pc.paymentMethods.map(method => ({
                methodId: method.paymentMethod.id,
                showAsQuickButton: method.showAsQuickButton,
                showInPaymentWindow: method.showInPaymentWindow,
            })),
        });
    }, [config, setDefaultValues]);

    const edit = useCallback(
        async (paymentMethodOrder: string[]) => {
            if (
                !config.data.paymentConfiguration ||
                !values ||
                !formData.data
            ) {
                return;
            }

            const sortedPaymentMethods = [...values.paymentMethods].sort(
                (a, b) => {
                    const resultA = paymentMethodOrder.indexOf(a.methodId);
                    const resultB = paymentMethodOrder.indexOf(b.methodId);
                    const result = resultA - resultB;
                    return result;
                }
            );

            await handleMutationError(
                async () =>
                    await paymentConfigurationEdit({
                        variables: {
                            id: config.data.paymentConfiguration.id,
                            paymentConfiguration: {
                                name: values.name,
                                paymentMethods: sortedPaymentMethods,
                            },
                        },
                    }),

                t(
                    "backoffice.payment_configuration.saved",
                    "Payment configuration saved"
                ),

                () => {
                    if (!config.data) {
                        // Something was not really successful
                        return;
                    }

                    if (
                        !route ||
                        !route.params ||
                        !route.params.referrer ||
                        route.params.referrer.route
                    ) {
                        // There is no referrer. Just back off.
                        return;
                    }

                    // Figure out where to redirect to...
                    if (route.params.referrer.route === "MERCHANT_SETTINGS") {
                        navigate("MERCHANT_SETTINGS");
                    }
                }
            );
        },
        [
            config,
            values,
            formData.data,
            handleMutationError,
            t,
            paymentConfigurationEdit,
            route,
            navigate,
        ]
    );

    if (!values || !formData.data) {
        return <LoadingScreen style="light" />;
    }

    /**
     * Merge the list of possible values (formData.data.paymentMethods) with
     * the list of saved values for this config (values.paymentMethods) to
     * produce a list with all the options (in which the previously selected/saved values
     * are on the top of the list
     *
     * (Long story short: Take the ordered list of saved values and add the difference between the two lists after that)
     */
    const initialSorting = [
        ...new Set([
            ...values.paymentMethods.map(pm => pm.methodId),
            ...formData.data.paymentMethods.data.map(pm => pm.id),
        ]),
    ];

    return (
        <PaymentConfigurationScreen>
            <Surface>
                <View style={styles.container}>
                    <PaymentConfigurationForm
                        form={form}
                        configurationId={configId}
                        initialSorting={initialSorting}
                        paymentMethodOptions={formData.data}
                        onSubmit={edit}
                        submitButton={["common.save", "Save"]}
                    />
                </View>
            </Surface>
        </PaymentConfigurationScreen>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        maxWidth: theme.dimensions.maxFormWidth,
    },
});

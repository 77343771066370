import React, { ReactNode } from "react";
import {
    Button,
    Headline,
    StyleFunction,
    Surface,
    useThemedStyle,
} from "@venuepos/react-common";
import { View } from "react-native";
import { useTranslation } from "locales";
import { ConditionAssignment } from "lib";

export function GroupFormBox({
    children,
    headline,
    onAddCondition,
    hasUnusedConditions,
    condition,
}: {
    headline: string;
    children: ReactNode;
    onAddCondition: (condition: ConditionAssignment) => void;
    hasUnusedConditions: boolean;
    condition: ConditionAssignment;
}) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();

    return (
        <Surface style={styles.surface}>
            <Headline size="h5">{headline}</Headline>
            {children}
            <View style={styles.actions}>
                <Button
                    onPress={() => onAddCondition(condition)}
                    variant="text"
                    size="small"
                    iconName="plus"
                    disabled={!hasUnusedConditions}
                >
                    {t(
                        "backoffice.discount_form.new_order_condition",
                        "Add condition"
                    )}
                </Button>
            </View>
        </Surface>
    );
}

const styleFunc: StyleFunction = theme => ({
    surface: {
        marginBottom: theme.spacingScale * 2,
    },
    actions: {
        marginTop: theme.spacingScale,
        flexDirection: "row",
        alignItems: "center",
    },
    addConditionButtonIcon: {
        color: theme.colors.textDark,
    },
    logicPicker: {
        width: "auto",
        marginRight: theme.spacingScale * 2,
        marginBottom: 0,
        paddingVertical: 4,
        minHeight: 32,
        borderColor: theme.colors.grey250,
    },
    inactiveSurface: {
        flexDirection: "row",
        marginBottom: theme.spacingScale * 2,
        borderColor: theme.colors.grey100,
        borderRadius: theme.borderRadius,
        borderStyle: "dashed",
        borderWidth: 2,
        backgroundColor: "transparent",
        padding: theme.spacingScale * 2,
    },
    inactiveIcon: {
        color: theme.colors.grey500,
    },
    inactiveText: {
        ...theme.fonts.regular,
        marginLeft: theme.spacingScale,
        color: theme.colors.grey500,
        fontSize: 16,
        fontWeight: "bold",
    },
    removeIcon: {
        color: theme.colors.textDark,
        marginTop: 2,
        marginLeft: 10,
    },
});

import {
    Alert,
    Button,
    CheckBox,
    ColorIndicator,
    ConfirmModal,
    DataTable,
    Icon,
    IconButton,
    percentageToRate,
    rateToPercentage,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    useMerchantConfig,
    useModal,
    usePagination,
    useSearch,
    useTheme,
    useThemedStyle,
    useToast,
} from "@venuepos/react-common";
import {
    useDiscountCreateMutation,
    useDiscountDeleteMutation,
    useDiscountsActiveToggleMutation,
    useDiscountsQuery,
} from "graphql-sdk";
import { numberFormat, SearchDefinition } from "lib";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "locales";
import { FlatList, View } from "react-native";

import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";
import { useHandleMutationError, useSearchDefinition } from "../../hooks";

type ScreenProps = RootStackScreenProps<"DISCOUNTS">;

export function DiscountListScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.discount");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [{ locale }] = useAdminSession(["locale"]);
    const { createSearchDefinition } = useSearchDefinition();
    const { currency } = useMerchantConfig();
    const theme = useTheme();
    const { render } = useModal();
    const { handleMutationError } = useHandleMutationError();
    const toast = useToast();
    const [deleteDiscount] = useDiscountDeleteMutation();
    const [createDiscount] = useDiscountCreateMutation();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination();

    const defaultDiscountSearch: SearchDefinition = {
        name: createSearchDefinition("name"),
        description: {
            name: t("searching.description", "Description"),
            type: "string",
            value: "",
            enabled: false,
        },
        createdAt: createSearchDefinition("createdAt"),
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultDiscountSearch, {
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    const [selectedDiscounts, setSelectedDiscounts] = useState<string[]>([]);
    const [allRowsSelected, setAllRowsSelected] = useState<boolean>(false);

    const { data, error, refetch } = useDiscountsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
    });
    const [toggleActiveDiscounts] = useDiscountsActiveToggleMutation();

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const handleDelete = useCallback(
        async (id: string) => {
            if (
                !(await render(onClose => (
                    <ConfirmModal
                        headerText={t(
                            "backoffice.discount.delete",
                            "Delete discount?"
                        )}
                        bodyText={t(
                            "backoffice.discount.delete_explain",
                            "Are you sure, you want to delete this discount?"
                        )}
                        onClose={onClose}
                    />
                )))
            ) {
                return;
            }

            await deleteDiscount({ variables: { id } });
            toast.success(t("backoffice.discount.deleted", "Discount deleted"));
            await refetch();
        },
        [deleteDiscount, refetch, render, t, toast]
    );

    const handleCopy = useCallback(
        async (id: string) => {
            if (
                !(await render(onClose => (
                    <ConfirmModal
                        headerText={t(
                            "backoffice.discount.copy",
                            "Copy discount?"
                        )}
                        bodyText={t(
                            "backoffice.discount.copy_explain",
                            "Are you sure, you want to copy this discount?"
                        )}
                        onClose={onClose}
                    />
                )))
            ) {
                return;
            }

            const discount = data?.discounts.data.find(itr => itr.id === id);

            if (!discount) {
                toast.error(
                    t(
                        "backoffice.discount.discount_not_found",
                        "Could not find discount"
                    )
                );
                return;
            }

            await handleMutationError(
                async () => {
                    await createDiscount({
                        variables: {
                            discount: {
                                name:
                                    discount.name +
                                    " " +
                                    t("common.copy", "copy"),
                                lineDescription: discount.lineDescription,
                                type: discount.type,
                                valueType: discount.valueType,
                                value: discount.value,
                                maxPerOrder: discount.maxPerOrder,
                                itemQuery: discount.itemQuery,
                                conditions: discount.conditions,
                                active: discount.active,
                            },
                        },
                    });
                },
                t("backoffice.discount.copied", "Discount copied"),
                async () => {
                    await refetch();
                }
            );
        },
        [
            createDiscount,
            data?.discounts.data,
            handleMutationError,
            refetch,
            render,
            t,
            toast,
        ]
    );

    const handleEdit = useCallback(
        (id: string) => {
            navigate("DISCOUNT_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleMultiSelect = useCallback(
        (selected: boolean, itemIds: string[]) => {
            let clonedSelectedDiscounts = [...selectedDiscounts];
            if (selected) {
                clonedSelectedDiscounts = [
                    ...clonedSelectedDiscounts,
                    ...itemIds,
                ];
            } else {
                clonedSelectedDiscounts = clonedSelectedDiscounts.filter(
                    i => !itemIds.includes(i)
                );
            }
            setSelectedDiscounts(clonedSelectedDiscounts);
        },
        [selectedDiscounts]
    );

    const toggleActiveSelectedDiscounts = useCallback(async () => {
        const toggleActiveDiscountsResponse = await render(onClose => (
            <ConfirmModal
                headerText={t("backoffice.discounts.toggle", "Toggle")}
                bodyText={t(
                    "backoffice.discounts.toggle_explain",
                    "If you choose to toggle these discounts, the state (active/in-active) will be changed to the opposite state.\n\nAre you sure, you want to toggle these discounts?"
                )}
                onClose={onClose}
            />
        ));

        if (!toggleActiveDiscountsResponse) {
            return;
        }

        const ids = selectedDiscounts.map(discountId => discountId);

        await handleMutationError(
            async () => {
                await toggleActiveDiscounts({ variables: { ids } });
            },
            t("backoffice.discounts.toggled", "Discounts toggled"),
            async () => {
                await refetch();
            }
        );

        setSelectedDiscounts([]);
        setAllRowsSelected(false);
    }, [
        handleMutationError,
        refetch,
        render,
        selectedDiscounts,
        t,
        toggleActiveDiscounts,
    ]);

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <AdminContainer>
            <View style={[theme.styles.row, styles.container]}>
                {auth.may("merchant.discount") ? (
                    <>
                        <Button
                            style={styles.button}
                            variant="invert"
                            disabled={!selectedDiscounts.length}
                            onPress={toggleActiveSelectedDiscounts}
                            testID="toggleDiscounts"
                        >
                            {t("backoffice.discounts.toggle", "Toggle")}
                        </Button>
                        <Spacer space={1} />
                        <Button
                            style={styles.button}
                            onPress={() => {
                                navigate("DISCOUNT_CREATE");
                            }}
                        >
                            {t(
                                "backoffice.discount_list.create_discount",
                                "Create discount"
                            )}
                        </Button>
                    </>
                ) : null}
                <Spacer space={1} />
                {searchIndicator}
            </View>
            <Spacer space={2} />
            <Surface>
                {searchComponent}
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title style={styles.iconColumn}>
                            <CheckBox
                                value={allRowsSelected}
                                onValueChange={value => {
                                    const allItemIds = data!.discounts.data.map(
                                        p => p.id
                                    );
                                    handleMultiSelect(value, allItemIds);
                                    setAllRowsSelected(value);
                                }}
                            />
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "active" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("active")}
                            style={styles.activeColumn}
                        >
                            {t("backoffice.user.active", "Active")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "name" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "lineDescription"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("lineDescription")}
                        >
                            {t(
                                "backoffice.discount_form.line_description",
                                "Receipt line description"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "type" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("type")}
                            style={styles.typeColumn}
                        >
                            {t("backoffice.discount_form.type.label", "Type")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "valueType"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("valueType")}
                            style={styles.valueTypeColumn}
                        >
                            {t(
                                "backoffice.discount_form.value_type.label",
                                "Calculation method"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "value" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("value")}
                            numeric
                            style={styles.valueColumn}
                        >
                            {t("backoffice.discount_form.value", "Value")}
                        </DataTable.Title>
                        <DataTable.Title style={styles.sortIcon}>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    <FlatList
                        data={data?.discounts.data}
                        renderItem={({ item }) => (
                            <DataTable.Row onPress={() => handleEdit(item.id)}>
                                <DataTable.Cell style={styles.iconColumn}>
                                    <CheckBox
                                        value={selectedDiscounts.includes(
                                            item.id
                                        )}
                                        onValueChange={value =>
                                            handleMultiSelect(value, [item.id])
                                        }
                                        style={styles.checkbox}
                                        testID={"select:" + item.name}
                                    />
                                </DataTable.Cell>
                                <DataTable.Cell style={styles.activeColumn}>
                                    <ColorIndicator
                                        status={
                                            item.active ? "success" : "warning"
                                        }
                                    />
                                </DataTable.Cell>
                                <DataTable.Cell>{item.name}</DataTable.Cell>
                                <DataTable.Cell>
                                    {item.lineDescription}
                                </DataTable.Cell>
                                <DataTable.Cell style={styles.typeColumn}>
                                    {t(
                                        `backoffice.discount_form.type.${item.type}`.toLocaleLowerCase(),
                                        `backoffice.discount_form.type.${item.type}`.toLocaleLowerCase()
                                    )}
                                </DataTable.Cell>
                                <DataTable.Cell style={styles.valueTypeColumn}>
                                    {t(
                                        `backoffice.discount_form.value_type.${item.valueType}`.toLocaleLowerCase(),
                                        `backoffice.discount_form.value_type.${item.valueType}`.toLocaleLowerCase()
                                    )}
                                </DataTable.Cell>
                                <DataTable.Cell
                                    numeric
                                    style={styles.valueColumn}
                                >
                                    {`${numberFormat(
                                        item.valueType === "PERCENTAGE"
                                            ? rateToPercentage(item.value)
                                            : percentageToRate(item.value),
                                        2,
                                        locale
                                    )} ${
                                        item.valueType === "PERCENTAGE"
                                            ? "%"
                                            : currency
                                    }`}
                                </DataTable.Cell>
                                <DataTable.Cell style={styles.sortIcon} numeric>
                                    <IconButton
                                        color={theme.colors.secondary}
                                        name="edit"
                                        onPress={() => handleEdit(item.id)}
                                    />
                                    <IconButton
                                        color={theme.colors.secondary}
                                        name="copy"
                                        onPress={() => handleCopy(item.id)}
                                    />
                                    <IconButton
                                        color={theme.colors.secondary}
                                        name="delete"
                                        onPress={() => handleDelete(item.id)}
                                    />
                                </DataTable.Cell>
                            </DataTable.Row>
                        )}
                        keyExtractor={(_, index) => index.toString()}
                    />

                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.discounts.pagination.pages}
                        itemCount={data?.discounts.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = () => ({
    container: {
        justifyContent: "flex-end",
        flexWrap: "wrap",
    },
    typeColumn: {
        flexBasis: 120,
        flexGrow: 0,
    },
    valueTypeColumn: {
        flexBasis: 160,
        flexGrow: 0,
    },
    valueColumn: {
        flexBasis: 140,
        flexGrow: 0,
    },
    iconColumn: {
        flexBasis: 60,
        flexGrow: 0,
        flexShrink: 0,
    },
    activeColumn: {
        flexBasis: 60,
        flexGrow: 0,
        flexShrink: 0,
    },
    sortIcon: {
        flexBasis: 100,
        flexGrow: 0,
        justifyContent: "flex-end",
    },
});
